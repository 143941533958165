import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import { Link } from "gatsby";
import FlipBook from "@/components/catalog/flipBook";
import folderIcon from "../../assets/svg/folderDownload.svg";

const Publication = ({ zoneContent }) => {
  const [sectionData] = zoneContent || [];
  let twinGodsApocalypseData = {};
  const [sectionContent, setSectionContent] = useState({});
  const [flipBookPages, setFlipBookPages] = useState([]);

  useEffect(() => {
    if (sectionData) {
      twinGodsApocalypseData = JSON.parse(
        sectionData?.ContentDTO?.ContentObject || "{}"
      );
      if (!isEmpty(twinGodsApocalypseData)) {
        if (twinGodsApocalypseData.images.length) {
          let obj = {
            bookImages: [],
            logos: [],
            images: [],
          };
          twinGodsApocalypseData.images.map(item => {
            if (item.imageType.toLowerCase() === "book") {
              if (item.hasOwnProperty("sortOrder")) {
                let convertedNumber = parseInt(item.sortOrder);
                if (!isNaN(convertedNumber)) {
                  item.sortOrder = convertedNumber;
                  obj.bookImages.push(item);
                } else {
                  item.sortOrder = 999;
                  obj.bookImages.push(item);
                }
              } else {
                obj.bookImages.push(item);
              }
            } else if (item.imageType.toLowerCase() === "logo") {
              obj.logos.push(item);
            } else {
              obj.images.push(item);
            }
          });
          setSectionContent(obj);
        }
      }
    }
  }, [sectionData]);

  useEffect(() => {
    if (!isEmpty(sectionContent)) {
      if (sectionContent.bookImages.length) {
        setFlipBookPages(
          sectionContent.bookImages.sort(
            (valueOne, valueTwo) =>
              parseInt(valueOne.sortOrder) - parseInt(valueTwo.sortOrder)
          )
        );
      }
    }
  }, [sectionContent]);

  return (
    <section
      className={
        sectionContent?.bookImages?.[0].name.includes("Haunt")
          ? `hauntCommonSection publications`
          : `commonSection publications`
      }
    >
      <Container>
        {sectionContent?.logos && (
          <Row className="publications-img" style={{ alignItems: "end" }}>
            {sectionContent?.logos.map((value, index) => (
              <Col
                lg={sectionContent?.logos?.length > 2 ? 4 : 6}
                md={sectionContent?.logos?.length > 2 ? 6 : 12}
                key={index}
              >
                <div className="text-center">
                  <Link
                    to={value?.redirectURL}
                    target="_blank"
                    style={
                      value?.redirectURL === "#"
                        ? { pointerEvents: "none", color: "#a1a1a1" }
                        : {}
                    }
                  >
                    <img src={value?.url} alt={value.name} />
                    <br />
                    {value?.buttonText}
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        )}
        <hr />
        {sectionContent?.bookImages?.[0].name.includes("Haunt") && (
          <section className="pt-5 mb-5">
            <div
              style={{
                textAlign: "center",
                fontSize: "35px",
                textDecoration: "underline",
              }}
            >
              Free Digital Content
            </div>
            <Container className="mt-2">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mt-3" style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                  <img src={folderIcon} className="pr-2" style={{width:"35px"}}></img>
                  <Link
                    style={{ color: "#000000", fontSize:"20px" }}
                    to="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/epic-book/20230612190348289_theHauntDigitalContent.zip"
                    target="_blank"
                  >
                    Download File
                  </Link>
                </div>
              </div>
            </Container>
          </section>
        )}
        {flipBookPages && (
          <Row>
            <Col lg={12} className="text-center flipbook-wrapper">
              <FlipBook pages={flipBookPages} />
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Publication;
