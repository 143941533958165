import React, { useMemo } from 'react';
export const DOTS = "...";

const ranges = (start, end) => {
    let length = end - start + 1
    return Array.from({ length }, (_, index) => index + start);
}
const usePagination = ({ totalData, currentPage, recordsPerPage, siblingCount = 1 }) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalData / recordsPerPage);
        const totalPageNumbers = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) {
            return ranges(1, totalPageCount);
        }
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = ranges(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = ranges(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = ranges(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }


    }, [totalData, currentPage, recordsPerPage, siblingCount])
    return paginationRange
}

export default usePagination;
