import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SectionTitle from "@/components/section-title";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

const VideoOne = ({ zoneContent }) => {
  const [isOpen, setOpen] = useState(false);
  const [sectionData] = zoneContent || [];
  const [sectionContent, setSectionContent] = useState();
  useEffect(() => {
    if (sectionData) {
      let sectionContentObj = JSON.parse(sectionData?.ContentDTO?.ContentObject || "{}");
      if (sectionContentObj) {
        let sectionData = {
          title: sectionContentObj.title,
          subTitle: sectionContentObj.subTitle,
          text: sectionContentObj.description,
          url: sectionContentObj.videoUrl,
          image: { title: sectionContentObj?.thumbnailImage?.name, url: sectionContentObj?.thumbnailImage?.url }
        };
        setSectionContent(sectionData);
      }
    }
  }, [sectionData]);

  return (
    <>
      {sectionContent && (
        <Fragment>
          <section className="commonSection our_work">
            <div className="videoWrap_2">
              <img src={sectionContent.image.url} alt={sectionContent.image.title} />
              <div className="play_video">
                <a
                  onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                  href="#"
                  className="video_popup"
                >
                  <i className="fa fa-play"></i>
                </a>
              </div>
            </div>
            <Row className="our_work_inner_wrapper">
              <Col lg={12} sm={12}>
                <div className="ab_detail_wrap">
                  <SectionTitle data={sectionContent} />
                </div>
              </Col>
            </Row>
          </section>
          <ModalVideo
            channel="custom"
            url={sectionContent.url}
            autoplay
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </Fragment>
      )}
    </>
  );
};

export default VideoOne;
