import React, { useEffect, useState } from "react";
import CustomMultiToggle from "./CustomMultiToggle";
import "react-multi-toggle/style.css";

const MultipleToggle = ({
  selectedOption,
  optionForm,
  questionDescription,
  questionName,
  setFieldValue,
  setOptionForm,
  setSelected,
  survey,
}) => {
  const [isVertical, setIsVertical] = useState(false);
  useEffect(() => {
    if (survey.questionOption.length) {
      const data = survey.questionOption.map(val => {
        if (val.option.name.length > 24) {
          setIsVertical(true);
        }
        return {
          displayName: val.option.name,
          value: val.option.name,
          className: "line-height",
          optionClass: "line-height",
        };
      });
      setOptionForm([...optionForm, ...data]);
    }
  }, []);
  return (
    <>
      {optionForm.length > 0 && (
        <CustomMultiToggle
          options={optionForm}
          selectedOption={selectedOption[questionDescription]}
          onSelectOption={value => {
            setSelected({ ...selectedOption, [questionDescription]: value });
            setFieldValue(questionDescription, value);
          }}
          label={questionName}
          setSelected={setSelected}
          isVertical={isVertical}
        />
      )}
    </>
  );
};

export default MultipleToggle;
