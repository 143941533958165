import React, { Fragment, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import SearchPopup from "@/components/search-popup";
import PopupMenu from "@/components/popup-menu";
import ZweihanderPopup from "@/components/zweihander-popup-menu";
import { Link as ScrollLink } from "react-scroll";

import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/icons.css";
import "@/css/preset.css";
import "@/css/theme.css";
import "@/css/responsive.css";
import "@/css/epics.css";
import "@/css/systems.css";

const Layout = ({ PageTitle, children, sectionThemeObj }) => {
  const { searchStatus } = useContext(SearchContext);
  const { menuStatus } = useContext(MenuContext);
  const { script } = useContext(MenuContext);
  const { reforgedMenuStatus } = useContext(MenuContext);
  const [scrollTop, setScrollTop] = useState(false);
  const renderScript = script?.ContentObject && JSON.parse(script?.ContentObject)
  const handleScrollTop = () => {
    if (window !== undefined) {
      if (window.scrollY > 70) {
        setScrollTop(true);
      } else if (window.scrollY < 70) {
        setScrollTop(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [scrollTop]);

  return (
    <Fragment>
      <Helmet>
        <script>
          {renderScript?.script}
        </script>
        <title>World of Game Design - {PageTitle?.MetaTitle ? `${PageTitle?.MetaTitle}` : `${PageTitle?.Name}`}</title>
        <link rel="canonical" href="https://www-d.wogd.com/" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="application-name" content="World of Game Design" />
        <meta name="title" content={PageTitle?.MetaTitle && PageTitle?.MetaTitle} />
        <meta name="description" content={PageTitle?.MetaDescription && PageTitle?.MetaDescription} />
        <meta name="image" content="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/content/20240309060050699_LogoWOGD.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www-d.wogd.com/" />
        <meta property="og:title" content="World of Game Design" />
        <meta property="og:description" content="Enter a realm of epic stories that explore endless worlds using the latest artists, writers, and technologies.  Whether you love miniatures, Roleplaying Games (RPGS), terrain, 3D printing, comics, board games, or card games; we think you will find something to entertain you at the World of Game Design" />
        <meta property="og:image" content="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/content/20240309060050699_LogoWOGD.png" />
        <meta property="og:site_name" content="World of Game Design" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/content/20240309060050699_LogoWOGD.png" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www-d.wogd.com/" />
        <meta property="twitter:title" content="World of Game Design" />
        <meta property="twitter:description" content="Enter a realm of epic stories that explore endless worlds using the latest artists, writers, and technologies.  Whether you love miniatures, Roleplaying Games (RPGS), terrain, 3D printing, comics, board games, or card games; we think you will find something to entertain you at the World of Game Design" />
        <meta property="twitter:image" content="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/content/20240309060050699_LogoWOGD.png" />
      </Helmet>
      <div id="wrapper">{children}</div>
      {searchStatus ? <SearchPopup /> : null}
      {menuStatus ? <PopupMenu /> : null}
      {reforgedMenuStatus ? <ZweihanderPopup sectionThemeObj={sectionThemeObj} /> : null}
      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          offset={-100}
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top showit"
        >
          <i className="fa fa-angle-double-up"></i>
        </ScrollLink>
      ) : null}
    </Fragment>
  );
};

export default Layout;
