import React, { useEffect, useState } from "react";
import { DiscussionEmbed } from "disqus-react"

const DisqusPage = ({ zoneContent }) => {
    const [heading, setHeading] = useState("");
    useEffect(() => {
        if (zoneContent && zoneContent?.length) {
            const title = zoneContent?.[0]?.ContentDTO?.ContentObject && JSON.parse(zoneContent?.[0]?.ContentDTO?.ContentObject)
            setHeading(title);
        }
    }, [zoneContent]);

    const disqusShortname = "world-of-game-design"; //found in your Disqus.com dashboard
    const disqusConfig = {
        url: "https://www-d.wogd.com",
        title: "Title of Your Article",
    };

    return (
        <div className="disqus-wrapper my-5 mx-auto" style={{ maxWidth: '70%', width: '100%' }}>
            <h2>{heading?.title}</h2>
            <DiscussionEmbed shortname={disqusShortname}
                config={disqusConfig} />
        </div>
    );
};

export default DisqusPage;
