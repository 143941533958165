import React from "react";
import { Link } from "gatsby";

const ChannelCard = ({ data }) => {
  const { name, description, redirectURL = "#", buttonText } = data;
  return (
    <div className="icon_box_2 text-center">
      {name && <h3>{name}</h3>}
      {description && <p>{description}</p>}
      <Link to={redirectURL} target="_blank">
        {buttonText ? buttonText : `View`}
      </Link>
    </div>
  );
};

export default ChannelCard;
