import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useAddRecordMutation } from '@/services/wogdApi';
import { successToast } from '../publisher/surveyForm/toast';
import Input from '../publisher/surveyForm/Input';

const AddMessage = ({ isOpen, setIsOpen, counterPartyId }) => {
  const [counterPartyActionLog] = useAddRecordMutation();

  const rejectMessageSchema = Yup.object().shape({
    Message: Yup.string("Enter Message").required("Message is required.")
  })

  const submitForm = (values) => {
    const actionLog = {
      counterPartyId: counterPartyId,
      actionName: "rejected",
      rejectedReason: values.Message
    }
    counterPartyActionLog({
      entity: "Doc/CreateCounterPartyActionLog",
      isWogdV2Api: true,
      data: actionLog
    }).then(res => {
      if (res) {
        successToast("Thanks for you response...")
        setIsOpen()
      }
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={setIsOpen} size="md">
        <ModalHeader toggle={setIsOpen} >
          Add Points
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              Message: ""
            }}
            validationSchema={rejectMessageSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const {
                errors,
                touched,
                values,
                handleSubmit,
                handleBlur,
                handleChange,
              } = formik;

              return (
                <Form >
                  <Input
                    label="Rejection Points"
                    name="Message"
                    type="textarea"
                    placeholder="Enter rejection message"
                    fieldValue={values.Message}
                    errors={errors.Message}
                    touched={touched.Message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <button onClick={handleSubmit}> Submit </button>
                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddMessage
