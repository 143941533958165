import React, { useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      <p>{props.children}</p>
    </div>
  );
});

function FlipBook({ pages }) {
  return (
    <HTMLFlipBook
      width={550}
      height={733}
      size="fixed"
      minWidth={315}
      maxWidth={1000}
      minHeight={400}
      maxHeight={1533}
      maxShadowOpacity={0.5}
      showCover={true}
      mobileScrollSupport={true}
      className="demo-book"
    >
      {pages &&
        pages.map((value, index) => {
          return (
            <Page number={value?.SortOrder} key={index}>
              <img
                src={value?.url}
                width="550"
                className="flipbook-img"
              />
            </Page>
          );
        })}
    </HTMLFlipBook>
  );
}

export default FlipBook