import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";

const ProductCatalog = ({ zoneContent }) => {
  const [zoneData] = zoneContent || [];
  const content = zoneData?.ContentDTO?.ContentObject && JSON.parse(zoneData?.ContentDTO?.ContentObject);

  const [showCatalog, setShowCatalog] = useState(false);
  const [currCatalog, setCurrCatalog] = useState({});

  const handleCloseCatalog = () => setShowCatalog(false);
  const handleShowCatalog = catalog => {
    setShowCatalog(true);
    setCurrCatalog(catalog);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  return (
    <section className="commonSection mt-5 pb-4">
      <Container>
        <div>
          <h2 className="mb-4">{content?.title && content?.title}</h2>
          {content?.lists?.length && (
            <div className="p-3">
              <ul className="row p-0">
                {content?.lists?.map((catalog, index) => (
                  <li key={index} className="text-center">
                    <Document
                      file={catalog?.pdfLink}
                      onClick={() => handleShowCatalog(catalog)}
                      className="catalog-img"
                    >
                      <Page pageNumber={1} renderAnnotationLayer={false} />
                    </Document>
                    <p>{catalog?.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Modal
            show={showCatalog}
            onHide={handleCloseCatalog}
            size="xl"
            className="catalog-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>{currCatalog?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <embed
                src={currCatalog?.pdfLink}
                type="application/pdf"
                width="100%"
                height="800px"
                className="catalog"
              />
            </Modal.Body>
          </Modal>
        </div>
      </Container>
    </section>
  );
};

export default ProductCatalog;
