import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { navigate } from 'gatsby';
import { useAddRecordMutation, useLazyGetRecordsQuery } from '@/services/wogdApi';
import { CONTRACT_TAG } from '@/services/tagFile';
import { errorToast, successToast } from '../publisher/surveyForm/toast';
import SignatureModal from './SignatureModal';
import AddMessage from './AddMessage';
import './SignatureModal.css';

const Contract = () => {
	const { isAuthenticated } = useAuth0();
	const [pdf, setPdf] = useState();
	const [counterPartyContract, setCounterPartyContract] = useState();
	const [isSignatureModalOpen, setIsSignatureModalOpen] = useState();
	const [currentUserSign, setCurrentUserSign] = useState({ signaturePad: "", savedSignature: "" });
	const [isSaveSign, setIsSaveSign] = useState(false);
	const [isRejectionModal, setIsRejectionModal] = useState(false);
	const sort = { sortDirection: 'desc', accessor: 'Id', };
	const [allCounterPartyMembers, setAllCounterPartyMembers] = useState()
	const [getAllCounterPartyContracts, { data: CounterPartyContracts, isLoading: isCounterPartyContractsLoading }] = useLazyGetRecordsQuery();
	const [ContractSignature, { data: contractSignature, isLoading: signatureLoading, isFetching: isFetchingSignature }] = useLazyGetRecordsQuery();
	const [counterPartyActionLog] = useAddRecordMutation();
	const [getPDF, { isLoading: loadingPDF }] = useAddRecordMutation();
	const [uploadSign] = useAddRecordMutation();
	const cookies = new Cookies();

	useEffect(() => {
		const Signatures = contractSignature?.value || []
		if (Signatures?.length) {
			const counterPartyMembers = contractSignature?.value?.map(member => {
				return {
					Role: member?.RoleTypeDTO?.Name,
					SortOrder: member?.RoleTypeDTO?.SortOrder,
					Signature: member?.ContractSignatureDTO?.[0]?.Signature,
					Name: member?.Name,
					Description: member?.Description
				}
			}).sort((member1, member2) => member1?.SortOrder - member2?.SortOrder)
			counterPartyMembers.forEach(members => {
				if (members?.Role === counterPartyContract?.counterPartyDTO?.[0]?.roleTypeDTO?.name) {
					setCurrentUserSign(prev => { return { ...prev, "savedSignature": members?.Signature } })
				}
			})
			setAllCounterPartyMembers(counterPartyMembers)
		}
	}, [contractSignature, counterPartyContract, isSaveSign])

	const downloadPDF = () => {
		setPdf("")
		let input = document.getElementById("divToPrint")
		let inputToString;
		if (input.outerHTML)
			inputToString = input.outerHTML;
		else if (XMLSerializer)
			inputToString = new XMLSerializer().serializeToString(input);

		getPDF({
			entity: "PubContent/GeneratePDFBaseOnContent",
			data: { htmlContent: inputToString },
			responseType: 'text'
		}).then(res => {
			if (!res?.error) {
				setPdf(res.data)
				successToast("PDF genetared...")
			}
			else {
				errorToast("Generate pdf failed...");
			}
		})
	}

	const uploadSignHandler = () => {
		const signDetails = {
			counterPartyId: counterPartyContract?.counterPartyDTO?.[0]?.id,
			signature: currentUserSign.savedSignature,
			contractId: counterPartyContract?.id
		}
		const actionLog = {
			counterPartyId: counterPartyContract?.counterPartyDTO?.[0]?.id,
			actionName: "sign"
		}
		uploadSign({
			entity: "Doc/ContractSignature",
			isWogdV2Api: true,
			data: signDetails
		}).then(() => {
			counterPartyActionLog({
				entity: "Doc/CreateCounterPartyActionLog",
				isWogdV2Api: true,
				data: actionLog
			})
			successToast("Signature uploaded")
			setIsSaveSign(false)
		})
	}

	useEffect(() => {
		if (counterPartyContract && (currentUserSign.savedSignature)) {
			downloadPDF()
		}
	}, [counterPartyContract, currentUserSign.savedSignature])

	useEffect(() => {
		if ((currentUserSign.savedSignature) && isSaveSign) {
			uploadSignHandler()
		}
	}, [isSaveSign])

	useEffect(() => {
		if (counterPartyContract) {
			const clearSign = { signaturePad: "", savedSignature: "" }
			setCurrentUserSign({ ...clearSign })

			const contractId = counterPartyContract?.id
			if (contractId) {
				ContractSignature({
					entity: `Doc/GetCounterPartiesByContractId/${contractId}`,
					isWogdV2Api: true,
				})
			}

		}
	}, [counterPartyContract, isSaveSign])

	useEffect(() => {
		if (isAuthenticated) {
			getAllCounterPartyContracts({
				columns: [],
				entity: "Doc/GetCounterPartyContracts",
				isWogdV2Api: true,
				tag: CONTRACT_TAG,
				sort: sort,
				globalFilter: [],
			})
		} else {
			if (!cookies.get("accessToken")) {
				errorToast("Please login to continue")
				setTimeout(() => {
					navigate("/");
				}, 3000);
			}
		}
	}, [isAuthenticated])

	return isAuthenticated && cookies.get("accessToken") ? (
		<>
			<Row lg={12} md={12} sm={12} className={`p-3 contract-div ${isCounterPartyContractsLoading && "d-flex justify-content-center"}`}>
				{isCounterPartyContractsLoading &&
					<Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />
				}
				{!isCounterPartyContractsLoading && <Col lg={6} md={12} sm={12}>
					<h3>Contract</h3>
					<div className='my-4 contract-form-div'>
						<FormGroup>
							<Label for="CounterParty-Contract" className="userInfo-label" >
								CounterParty Contract
							</Label>
							<Select
								className={"userInfo-input"}
								classNamePrefix="select"
								isClearable={true}
								isSearchable={true}
								isLoading={isCounterPartyContractsLoading}
								name="CounterPartyContract"
								id="CounterPartyContract"
								defaultValue={counterPartyContract ? { label: counterPartyContract.name, value: counterPartyContract } : ""}
								options={CounterPartyContracts?.map(CounterPartyContract => {
									return { label: CounterPartyContract.name, value: CounterPartyContract }
								})}
								onChange={(value) => {
									setCounterPartyContract(() => value?.value)
								}}
							/>
						</FormGroup>
					</div>
					<div className='btn-collection mb-4'>
						<button disabled={(!counterPartyContract || currentUserSign.savedSignature || signatureLoading || isFetchingSignature)} onClick={() => setIsSignatureModalOpen(counterPartyContract?.counterPartyDTO?.[0]?.roleTypeDTO?.name?.toLowerCase())}>Sign</button>
						<button disabled={(!counterPartyContract || currentUserSign.savedSignature || signatureLoading || isFetchingSignature)} onClick={() => setIsRejectionModal(true)}>Reject</button>
						{counterPartyContract && <a href={`data:application/pdf;base64,${pdf}`} attributes-list download={counterPartyContract?.name || "Contract.pdf"} >
							<button disabled={loadingPDF || !pdf || isFetchingSignature}>
								<span style={{ display: "flex", alignItems: "center" }}>
									{loadingPDF && <Spinner animation="border" size="sm" variant="primary" style={{ height: '1rem', width: '1rem', marginRight: "5px" }} />}
									Download
								</span>
							</button>
						</a>}
					</div>
				</Col>}
				{counterPartyContract && <div style={{ border: "2px solid black", margin: "0", padding: "0", minWidth: "750px", width: "750px", display: "none" }} >
					<div id='divToPrint' style={{ minHeight: "1000px", width: "750px", padding: "2rem", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
						<div>
							{
								counterPartyContract?.contractMediaDTO?.[0]?.mediaDTO?.url && <div style={{ display: "flex", justifyContent: "center" }}>
									<img style={{ width: "50%" }} src={counterPartyContract?.contractMediaDTO?.[0]?.mediaDTO?.url} />
								</div>
							}
							<div style={{ height: "100px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
								<h2 style={{ fontWeight: "400", color: '#a6a6a6', marginBottom: 0 }}>Service Order</h2>
								<h4 style={{ fontWeight: "400", color: '#a6a6a6' }}>{counterPartyContract?.name}</h4>
							</div>
							{
								counterPartyContract?.contractClauseDTO?.map((contractClause, index) => (
									<span key={index}>
										<b>{contractClause?.clauseDTO?.name}</b> : <span>{contractClause?.clauseDTO?.fullText}</span> <br /><br />
									</span>
								))
							}
						</div>
						{allCounterPartyMembers?.length &&
							<div style={{ paddingBottom: "1rem", paddingTop: "3rem", display: "inline-block", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
								{allCounterPartyMembers?.map((member, memberIndex) => {
									return <div key={memberIndex} style={{ display: "flex", flexDirection: "column", minWidth: "40%" }}>
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<h4 style={{ paddingBottom: member?.Signature ? "0px" : "80px" }}>{member?.Role || ""}</h4>
										</div>
										{signatureLoading && <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />}
										{member.Signature && (
											<img
												style={{ height: "80px", width: "100%" }}
												alt="creator-signature"
												src={member?.Signature || ""}
											/>
										)}
										<hr style={{ border: "1px solid black", width: "100%" }} />
										<h5 style={{ marginBottom: "0px" }}>{member?.Name || ""}</h5>
										{member?.Description || ""}
									</div>
								})}
							</div>}
					</div>
				</div>}
				{counterPartyContract &&
					<Col lg={6} md={12} sm={12}>
						<div className="custom-container">
							<div className="custom-content">
								<div>
									{
										counterPartyContract?.contractMediaDTO?.[0]?.mediaDTO?.url && <div className='custom-image'>
											<img src={counterPartyContract?.contractMediaDTO?.[0]?.mediaDTO?.url} width={50} />
										</div>
									}
									<div className='custom-header'>
										<h2>Service Order</h2>
										<h4>{counterPartyContract?.name}</h4>
									</div>
									{
										counterPartyContract?.contractClauseDTO?.map((contractClause, index) => (
											<span key={index}>
												<b>{contractClause?.clauseDTO?.name}</b> : <span>{contractClause?.clauseDTO?.fullText}</span> <br /><br />
											</span>
										))
									}
								</div>
								{allCounterPartyMembers?.length &&
									<div className='custom-description'>
										{allCounterPartyMembers?.map((member, memberIndex) => {
											return <div key={memberIndex} className='custom-signature'>
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<h4 style={{ paddingBottom: member?.Signature ? "0px" : "80px" }}>{member?.Role || ""}</h4>
												</div>
												{signatureLoading && <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />}
												{member.Signature && (
													<img
														style={{ height: "80px", width: "100%" }}
														alt="creator-signature"
														src={member?.Signature || ""}
													/>
												)}
												<hr style={{ border: "1px solid black", width: "100%" }} />
												<h5 style={{ marginBottom: "0px" }}>{member?.Name || ""}</h5>
												{member?.Description || ""}
											</div>
										})}
									</div>}
							</div>
						</div>
					</Col>}
			</Row>
			{isSignatureModalOpen && <SignatureModal
				isOpen={isSignatureModalOpen}
				setIsOpen={() => setIsSignatureModalOpen(false)}
				saveSign={() => setIsSaveSign(true)}
				currentUserSign={currentUserSign}
				setCurrentUserSign={setCurrentUserSign}
			/>}
			{isRejectionModal && <AddMessage
				isOpen={isRejectionModal}
				setIsOpen={() => setIsRejectionModal(false)}
				counterPartyId={counterPartyContract?.counterPartyDTO?.[0]?.id}
			/>}
		</>
	) : <div className='d-flex justify-content-center align-items-center' style={{ marginTop: "75px", height: "100px" }}><Spinner>Please login to continue...</Spinner></div>
}

export default Contract
