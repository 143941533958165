import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isEmpty } from 'lodash';

const AboutTwo = ({ zoneContent }) => {
  const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)

  return (
    <section>
      {!isEmpty(sectionContentObj) && (
        <Container className="commonSection ab_agency">
          <Row className="m-0">
            <Col lg={6} md={6} sm={12} className="PR_79">
              {sectionContentObj?.subTitle && <h4 className="sectionSubTitle">{sectionContentObj?.subTitle}</h4>}
              {sectionContentObj?.title && <h2 className="sectionTitle MB_45">{sectionContentObj?.title}</h2>}
              {sectionContentObj?.description && <p className="sectionDescription">{sectionContentObj?.description}</p>}
              {sectionContentObj?.buttonText && <a className="common_btn red_bg" href={sectionContentObj?.redirectURL}>
                <span>{sectionContentObj?.buttonText}</span>
              </a>}
            </Col>
            <Col lg={6} md={6} sm={12}>
              {sectionContentObj && sectionContentObj.images &&
                <div className={`ab_img mt-md-0 mt-3`}>
                  <img className="w-100" src={sectionContentObj?.images[0]?.url} alt="" />
                </div>
              }
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default AboutTwo;
