import React from "react";

const TextArea = ({
  handleChange,
  questionName,
  questionDescription,
  label,
  inputIcon,
  isFocused,
  setIsFocused,
  handleBlur,
  errors,
  touched,
  surveyName,
}) => {
  return (
    <div className="input-group mb-30">
      {inputIcon && (
        <span className="input-group-addon">
          <i className={`ti-${inputIcon} text-primary`}></i>
        </span>
      )}
      <label>{label && questionName}</label>
      <textarea
        className={touched[surveyName] &&
        errors[surveyName] && 'input-outline'}
        as="textarea"
        name={questionDescription}
        rows="4"
        placeholder={!label && questionName}
        onBlur={handleBlur}
        onChange={handleChange}
        onMouseEnter={() => setIsFocused(!isFocused)}
      ></textarea>
    </div>
  );
};

export default TextArea;
