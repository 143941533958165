import React from "react";
const PortfolioCard = ({ data }) => {
  const { image, title, categories, url } = data;

  return (
    <div className={`singlefolio`}>
      <img src={image} alt={title} />
      <div className="folioHover">
        <div className="cate">
          {categories?.length && categories.map(cat => cat + ", ")}
        </div>
        <h4>{url ? <a href={url}>{title}</a> : title}</h4>
      </div>
    </div>
  );
};

export default PortfolioCard;
