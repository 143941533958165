import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import '@/css/zweihander.css'

const StoryTeller = ({ zoneContent, className }) => {
    const [sectionData] = zoneContent || [];
    const publicationData = JSON.parse(
        sectionData?.ContentDTO?.ContentObject || "{}"
    );

    return (
        <Container className={`${className}`}>
            <Row className='my-5'>
                {publicationData?.images.map((data, index) => {
                    return <Col lg={4} key={`Block${index}`}>
                        <div className='d-flex flex-column align-items-center'>
                            <span className='storyTellerHeading text-center mb-3'>{data?.title}</span>
                            <img src={data?.url} alt={data?.name} width={'100%'} />
                            <p className='storyTellerDescription text-center my-4'>
                                {data?.description}
                            </p>
                        </div>
                    </Col>
                })
                }
            </Row>
        </Container>
    )
}

export default StoryTeller