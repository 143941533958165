import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import _ from "lodash";
import LicensingTradmarks from "./licensing-tradmarks";
import LicensingLogos from "./licensing-logos";
import LicensingCopyrights from "./licensing-copyrights";
import Accordion from "./accordion";

const Licensing = ({ zoneContent }) => {
  const [zoneData] = zoneContent || [];
  const content = JSON.parse(zoneData?.ContentDTO?.ContentObject);
  const [tabs, setTabs] = useState([]);
  const [key, setKey] = useState("Logos");

  const tabComponent = {
    LicensingLogos: <LicensingLogos content={content} />,
    LicensingTradmarks: <LicensingTradmarks content={content} />,
    LicensingCopyrights: <LicensingCopyrights content={content} />,
  };

  useEffect(() => {
    const tab = content.lists.filter(list => _.has(list, "component"));
    setTabs(tab);
  }, []);

  return (
    <section className="commonSection">
      <Container>
        <div className="licensing">
          <div className="d-md-block d-none">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              className="mb-3"
              fill
            >
              {tabs.map(({ name, component }, index) => (
                <Tab eventKey={name} key={index} title={name}>
                  {tabComponent[`${component}`] ? (
                    tabComponent[`${component}`]
                  ) : (
                    <h4 className="my-md-5 my-4">{name}</h4>
                  )}
                </Tab>
              ))}
            </Tabs>
          </div>
          <div className="d-md-none d-block">
            {tabs.map(({ name, component }, index) => (
              <Accordion
                title={name}
                key={index}
                content={
                  tabComponent[`${component}`] ? (
                    tabComponent[`${component}`]
                  ) : (
                    <h4 className="my-md-5 my-4">{name}</h4>
                  )
                }
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Licensing;
