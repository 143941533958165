import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const LicensingTradmarks = ({ content }) => {
  const [trademarkList, setTrademarkList] = useState([]);

  useEffect(() => {
    const trademarks = Object.keys(content).reduce((list, key) => {
      key.includes("listTitle") && list.push({ title: content[key] });
      return list;
    }, []);
    setTrademarkList(trademarks);
  }, []);

  return (
    <section className="my-md-5 my-4">
      <Container>
        <div className="trademark">
          {content.title2 && <h4 className="mb-4">{content.title2}</h4>}
          {trademarkList.length &&
            trademarkList.map(({ title }, index) => (
              <div key={index}>
                {title && <h5>{title}</h5>}
                {title && (
                  <ul className="pl-0 mb-4">
                    {content.lists.length &&
                      content.lists.map(
                        (list, index) =>
                          list.listTitle === title && (
                            <li key={index}>{list.name}</li>
                          )
                      )}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default LicensingTradmarks;
