import React from "react";
import defaultImage from '../assets/images/bg/8.jpg';
import { isEmpty } from "lodash";
import '../assets/css/zweihander.css';

const PageBanner = ({ zoneContent, className }) => {
  const [creatorSection] = zoneContent || []
  const pageBannerRecords = JSON.parse(creatorSection?.ContentDTO?.ContentObject || "{}")
  const [pageBannerBackgroundImage] = pageBannerRecords?.images?.slice(-1) || []

  const pageBannerStyle = pageBannerBackgroundImage || Object.keys(pageBannerRecords).includes('banner') ?
    {
      background: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(${pageBannerBackgroundImage?.url || pageBannerRecords?.banner?.url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top, center",

    } : {
      backgroundImage: `url(${defaultImage})`
    }

  return (
    <>
      {!isEmpty(pageBannerRecords) ?
        <section className={`pageBanner ${className}`} style={pageBannerStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner_content text-center">
                  {
                    pageBannerRecords?.subTitle && <h4 className={window.location.pathname === "/zweihander" ? "zweihanderBannerSubtitle" : ""}>{pageBannerRecords.subTitle}</h4>
                  }
                  {
                    pageBannerRecords?.title && <h2 className={window.location.pathname === "/zweihander" ? "zweihanderBannerTitle" : ""}>{pageBannerRecords.title}</h2>
                  }
                </div>
              </div>
            </div>
          </div>
        </section> : ""
      }
    </>
  );
};

export default PageBanner;
