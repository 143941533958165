import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import PublicationCard from "@/components/epics/publicationCard";

const Publications = ({ zoneContent, className }) => {
  const [sectionData] = zoneContent || [];
  const publicationData = JSON.parse(
    sectionData?.ContentDTO?.ContentObject || "{}"
  );

  return (
    <>
      {!isEmpty(publicationData) && (
        <section className={`commonSection publications ${className}`}>
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                {publicationData?.subTitle && (
                  <h4 className="sectionSubTitle">
                    {publicationData?.subTitle}
                  </h4>
                )}
                {publicationData?.title && (
                  <h2 className="sectionTitle ">{publicationData?.title}</h2>
                )}
                {publicationData?.description && (
                  <p className="sectionDescription" style={{ margin: !publicationData?.images && 0 }}>
                    {publicationData?.description}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              {publicationData?.images &&
                publicationData?.images?.length &&
                publicationData.images.map(
                  (item, index) =>
                    item.url && (
                      <Col lg={4} md={6} sm={12} key={index}>
                        <PublicationCard data={item} />
                      </Col>
                    )
                )}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Publications;
