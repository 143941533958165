import * as React from "react";
import { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { CircularProgress } from "@mui/material";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = (typeof window !== 'undefined') && window.location;
  let pathName = location?.pathname?.toLowerCase()

  useEffect(() => {
    const handleNavigation = async () => {
      if (pathName !== location?.pathname) {
        await navigate(pathName);
      } else {
        setIsLoading(false);
      }
    };

    if (location && pathName) {
      handleNavigation();
    }
  }, [location, pathName])

  return (
    <>
      {isLoading ?
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <CircularProgress
            color="inherit"
            disableShrink
            size="3rem"
          />
        </div> :
        <Layout>
          <SEO title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
      }
    </>
  );
};

export default NotFoundPage;
