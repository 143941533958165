import React from "react";
import { Container } from "react-bootstrap";

const LicensingCopyrights = ({ content }) => {
  return (
    <section className="my-md-5 my-4">
      <Container>
        <div className="copyrights">
          {content.title1 && <h4 className="mb-4">{content.title1}</h4>}
          {content.title1 && (
            <ul className="pl-0 mb-0">
              {content.lists.length &&
                content.lists.map(
                  (list, index) =>
                    list.listTitle === content.title1 && (
                      <li key={index}>{list.name}</li>
                    )
                )}
            </ul>
          )}
        </div>
      </Container>
    </section>
  );
};

export default LicensingCopyrights;
