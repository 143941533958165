import React from "react";

const ContactInfos = ({ zoneContent }) => {
  const [contact] = zoneContent || []
  const contactInfosData = JSON.parse(contact?.ContentDTO?.ContentObject || "{}")
  return (
    <>
      {
        Object.entries(contactInfosData).length &&
        <section className="commonSection client_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                {contactInfosData?.subTitle && <h4 className="sectionSubTitle">{contactInfosData?.subTitle}</h4>}
                {contactInfosData?.title && <h2 className="sectionTitle">{contactInfosData.title}</h2>}
                {contactInfosData?.description && <p className="sectionDescription ">{contactInfosData?.description}</p>}
              </div>
            </div>
            <div className="row">
              {contactInfosData?.lists?.length ?
                contactInfosData?.lists?.map((List, index) => (
                  <div
                    className="col-lg-3 col-sm-6 col-md-3"
                    key={`contact-infos-${index}`}
                  >
                    <div className="singleClient_2" style={{ height: "242px" }}>
                      <h3>{List.name}</h3>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {`${List?.address}`}
                      </p>
                    </div>
                  </div>
                )) : ""}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default ContactInfos;
