import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { getShadow } from '../utils/shadow';
import { Navigation } from 'swiper/modules';
import "swiper/css"
import 'swiper/css/navigation';

const CharacterSection = ({ zoneContent, activeSection, sectionThemeObj }) => {
    const [character, setCharacter] = useState()
    const [displayCharacter, setDisplayCharacter] = useState()
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const fontStyle = getShadow(sectionThemeObj?.fontShadow);

    useEffect(() => {
        setCharacter(sectionContentObj?.dynamicFieldList?.[0]?.heroImage.name)
    }, []);

    useEffect(() => {
        if (character) {
            const filteredCharacter = sectionContentObj?.dynamicFieldList?.reduce((acc, crr) => {
                if (character?.toLowerCase().includes(crr.heroImage.name.toLowerCase())) acc = crr
                return acc
            }, {})
            setDisplayCharacter(filteredCharacter)
        }
    }, [character])

    const changeCharacter = (name) => {
        const newName = name?.split(' ')[0]
        setCharacter(newName)
    }

    return (
        <>
            <div id="character-page" className={`section`}>
                <div className='hero-logo-block'>
                    <div className='hero-logo-bar'>
                        <img src={sectionContentObj?.borderImage?.url} className='hero-logo-border' />
                        {
                            sectionContentObj?.dynamicFieldList?.map((image, i) => (
                                <div key={`Logo${i}`} className='hero-logo' onClick={() => changeCharacter(image?.heroImage?.name)}>
                                    <img src={image?.characterLogo?.url} style={{ display: image?.heroImage?.name?.toLowerCase().includes(character?.toLowerCase()) ? 'none' : '' }} />
                                    {image?.heroImage?.name.toLowerCase().includes(character?.toLowerCase()) &&
                                        <>
                                            <img src={image?.activeCharacterLogo?.url} />
                                            <img src={sectionContentObj?.borderEnhancement?.url} className='active-logo' />
                                        </>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className='hero-details'>
                        <img src={displayCharacter?.characterTitleImage?.url} className='title' />
                        <h2 style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "", textShadow: fontStyle}}>{displayCharacter?.heroWeakness}</h2>
                        <div className='pt-3'><p style={{ color: sectionThemeObj ? `${sectionThemeObj?.description}` : "",textShadow: fontStyle }} >{displayCharacter?.heroDiscription}</p></div>
                    </div>
                </div>
                {sectionContentObj?.dynamicFieldList?.map((list, index) => (
                    <div key={`character${index}`} className='character-container' style={{ opacity: character?.toLowerCase().includes(list.heroImage.name.toLowerCase()) ? 1 : 0 }}>
                        <div className={`character-section hero-${index + 1} ${character?.toLowerCase().includes(list.heroImage.name.toLowerCase()) && activeSection === 'heroes' ? 'active' : ''}`} >
                            <div className='bg' style={{ backgroundImage: `url(${list?.background1?.url})` }} />
                            <div className='prospect' style={{ backgroundImage: `url(${list?.background2?.url})` }} />
                            <div className='fog' style={{ backgroundImage: `url(${list?.fogImage?.url})` }} />
                            <div className='hero'>
                                <img src={list?.heroImage?.url} alt={list?.heroImage?.name} />
                            </div>
                        </div>
                    </div>
                ))
                }
                <div className='heroes-title-image'>
                    {/* <img src={sectionContentObj?.titleImage?.url} /> */}
                    <h5 className='title-text' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "", textShadow: fontStyle }}>{sectionContentObj?.title.toUpperCase()}</h5>
                </div>
                <Swiper
                    slidesPerView={1}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    className='heroes-slider'
                    loop={true}
                    allowTouchMove
                    centeredSlides={true}
                    modules={[Navigation]}
                >
                    <div className='heroes-mobile-title-image'>
                        <h5 className='title-text' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "", textShadow: fontStyle }}>{sectionContentObj?.title.toUpperCase()}</h5>
                    </div>
                    {sectionContentObj?.mobileImages.map((list, i) => (
                        <SwiperSlide key={`Feature${i}`}>
                            {/* <img src={sectionContentObj?.titleImage?.url} className='heroes-mobile-title-image' /> */}
                            <img src={list?.image1?.url} className='character-bg' />
                            <div className="content">
                                <img src={list?.image2?.url} />
                                <div className="character-weakness">
                                    <span style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "", textShadow: fontStyle }}>{list?.description}</span>
                                </div>
                            </div>
                        </SwiperSlide>))
                    }
                    <div className='swiper-button-prev'><img className='w-100' src={sectionContentObj?.prevSlide.url} /></div>
                    <div className='swiper-button-next'><img className='w-100' src={sectionContentObj?.nextSlide.url} /></div>
                </Swiper>
            </div>
        </>
    )
}

export default CharacterSection