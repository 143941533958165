import React, { useState } from "react";
import { Link } from "gatsby";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const TerrainCard = ({ data }) => {
  const { url, name, redirectURL = "#" } = data;
  const [show, setShow] = useState(false);
  const terrainCardStyle = data
    ? { backgroundImage: `url(${url})` }
    : { backgroundImage: none };

  return (
    <>
      <Link
        className="terrain_box text-center"
        to={redirectURL}
        target="_blank"
      >
        <div className="flipper" onClick={() => setShow(true)}>
          <div className="front" style={terrainCardStyle}>
            {name && <h3>{name}</h3>}
          </div>
          <div className="back" style={terrainCardStyle}>
            {name && <h3>{name}</h3>}
          </div>
        </div>
      </Link>
      {show && (
        <Lightbox
          imageTitle={name}
          mainSrc={url}
          onCloseRequest={() => setShow(false)}
        />
      )}
    </>
  );
};

export default TerrainCard;
