import React from "react";
import "../assets/css/privacy-policy.css"

const PrivacyPolicy = ({ zoneContent }) => {
	const [privacyPolicyData] = zoneContent || [];
	const privacyPolicyDetails = JSON.parse(privacyPolicyData?.ContentDTO?.ContentObject || "{}");
	return (
		<>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full content-inner-2">
						<div className="container mt-5">
							<div className='description' dangerouslySetInnerHTML={{ __html: privacyPolicyDetails?.description }} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
