import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { isEmpty } from "lodash";
import "swiper/css"
import "swiper/swiper-bundle.css";
import 'swiper/css/pagination';

const Characters = ({ zoneContent }) => {
  const characterData = zoneContent?.[0]?.ContentDTO?.ContentObject && JSON.parse(zoneContent?.[0]?.ContentDTO?.ContentObject || "{}");

  return (
    <>
      {!isEmpty(characterData) && (
        <section className="commonSection client">
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                {characterData?.subTitle && (
                  <h4 className="sectionSubTitle">{characterData?.subTitle}</h4>
                )}
                {characterData?.title && (
                  <h2 className="sectionTitle">{characterData?.title}</h2>
                )}
                {characterData?.description && (
                  <p className="sectionDescription ">
                    {characterData?.description}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Swiper className="client_slider"
                  spaceBetween={0}
                  loop={true}
                  slidesPerView={1}
                  modules={[Pagination]}
                  pagination={{
                    el: "#client-carousel-pagination",
                    type: "bullets",
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      spaceBetween: 0,
                      slidesPerView: 2,
                      slidesPerGroup: 2,
                    },
                    576: {
                      spaceBetween: 30,
                      slidesPerView: 2,
                      slidesPerGroup: 2,
                    },
                    992: {
                      spaceBetween: 30,
                      slidesPerView: 3,
                      slidesPerGroup: 3,
                    },
                    1200: {
                      spaceBetween: 30,
                      slidesPerView: 4,
                      slidesPerGroup: 4,
                    },
                  }}>
                  {characterData &&
                    characterData?.images?.map(({ url, name }, index) => (
                      <SwiperSlide key={index}>
                        <div className="singleClient">
                          <Link to="#">
                            <img src={url} alt={name} />
                          </Link>
                        </div>
                      </SwiperSlide>
                    ))}
                  <div
                    className="swiper-pagination"
                    id="client-carousel-pagination"
                  ></div>
                </Swiper>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Characters;
