import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastPosition = toast.POSITION.BOTTOM_RIGHT;
export const successToast = message => {
  toast.success(message, { position: toastPosition });
};

export const errorToast = message => {
  toast.error(message, { position: toastPosition });
};
export const toastMessage = message => {
  toast(message, { position: toastPosition });
};
