import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { SUBSCRIPTION_EMAIL_TAG } from "@/services/tagFile";
import { useAddRecordMutation } from "@/services/wogdApi";
import { errorToast, successToast } from "./publisher/surveyForm/toast";

const SubscribeForm = ({ zoneContent }) => {
  const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)

  const [addSubscribeEmail, { isLoading: isSubscribeEmailLoading },] = useAddRecordMutation();

  const emailValidationSchema = Yup.object().shape({
    Email: Yup.string().email().required(),
  });
  const onSubmit = (values, { resetForm }) => {
    if (values) {
      const subscriptionDetail = {
        SubscriptionEmail: values.Email
      }
      addSubscribeEmail({
        entity: "User/UserSubscriptionEmail",
        data: subscriptionDetail,
        tag: SUBSCRIPTION_EMAIL_TAG,
      }).then(response => {
        if (!response.error) {
          successToast(`You have successfully subscribed.`);
          resetForm();
        } else {
          if (response.error.data) {
            errorToast("You have already subscribed.");
          } else {
            errorToast("Something went wrong.");
          }
        }
      });
    }
  }
  return (
    <Formik
      initialValues={{
        Email: ""
      }}
      validationSchema={emailValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          values
        } = formik;
        return (
          <Form>
            <section>
              {!isEmpty(sectionContentObj) && (
                <Container className="commonSection subscribe">
                  <Row className="m-0 px-2" >
                    <Col lg={4}>
                      {sectionContentObj && <h4 className="sectionSubTitle text-center text-lg-left mb-4 mb-lg-0">{sectionContentObj?.title}</h4>}
                    </Col>
                    <Col lg={8}>
                      <Row className="subscribefrom justify-content-center">
                        <input type="email" placeholder={sectionContentObj?.placeholder} value={values.Email} onChange={handleChange} name="Email" required />
                        <button className="common_btn red_bg"
                          disabled={isSubscribeEmailLoading}
                          type="submit"
                          onClick={handleSubmit} >
                          {(isSubscribeEmailLoading) && (
                            <Spinner className="spinner" size="sm" animation="border" role="status" />
                          )}
                          {" "}
                          <span>{sectionContentObj?.buttonText}</span>
                        </button>
                      </Row>
                      <small className="text-danger ml-5"><ErrorMessage name="Email" /></small>
                    </Col>
                  </Row>
                </Container>
              )
              }
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubscribeForm;
