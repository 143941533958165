import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import moment from "moment";
import HeaderOne from "@/components/header-one";
import '@/css/zweihander.css'
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import Layout from "./layout";

const CommonBlog = ({ pageContext: { applicationScreensData, blog }, location }) => {
	const applicationDetails = { applicationScreen: applicationScreensData };
	const blogDetails = blog && JSON.parse(blog?.ContentTagDTO?.[0]?.ContentDTO?.ContentObject);
	const globalDataObj = applicationScreensData?.menuData.length && JSON.parse(applicationScreensData?.menuData?.[0]?.MenuDTO?.ContentObject)

	const [menuData, setMenuData] = useState([]);
	const [headerDetail, setHeaderDetail] = useState();
	const [screenTheme, setScreenTheme] = useState({});

	const currentLocation = (typeof window !== 'undefined') && window.location;
	const screenMapping = applicationDetails?.applicationScreen?.screenMapping
	const isLongWebAddress = screenMapping?.find(item =>
		currentLocation?.origin === item?.LongWebAddress ||
		currentLocation?.origin?.replace("//www.", "//") === item?.LongWebAddress ||
		currentLocation?.hostname === item?.LongWebAddress ||
		"www." + currentLocation?.hostname === item?.LongWebAddress
	)

	useEffect(() => {
		const screenMenu = applicationDetails?.applicationScreen?.reforgedMenu?.filter(menu => menu?.ScreenDTO?.Path === location?.state?.screenPath)
		const headerThemeObj = applicationDetails?.applicationScreen?.screenTheme?.filter(menu => menu?.ScreenDTO?.Path === location?.state?.screenPath)
		setScreenTheme(headerThemeObj?.[0]?.ThemeDTO.ContentObject ? JSON.parse(headerThemeObj?.[0]?.ThemeDTO.ContentObject) : {})
		if (isLongWebAddress) {
			setMenuData(applicationDetails?.applicationScreen?.reforgedMenu?.filter(item => item?.ScreenDTO?.Name === isLongWebAddress?.ScreenDTO?.Name))
		} else {
			setMenuData(screenMenu?.length ? screenMenu : applicationDetails?.applicationScreen?.reforgedMenu)
		}
		// setMenuData(applicationScreensData?.reforgedMenu);
		let header = applicationScreensData?.applicationContent?.map(
			value => value?.ContentDTO
		);
		setHeaderDetail(header);
	}, []);

	return (
		<MenuContextProvider>
			<SearchContextProvider>
				<Layout>
					<section id="blog-container" className="blog-section section-gap-top h-100" style={{ backgroundImage: `url(${blogDetails?.blogBackgroundImage?.url})`, backgroundSize: 'cover', paddingTop: "70px" }}>
						<HeaderOne
							menuData={menuData}
							headerDetail={headerDetail}
							fullPage
							blogHeader
							sectionThemeObj={screenTheme}
						/>
						<div className="container">
							<div className="back-img-block">
								<span className="blog-back-btn" onClick={() => navigate(-1)}><img src={`${globalDataObj?.backImage?.url}`} /></span></div>
							<div className="row justify-content-center m-0">
								<div className="col-lg-8">
									<div className="blog-loop standard-blog row">
										<div className="col-12 p-0">
											<div className="single-post-box">
												<div className="post-thumb">
													<img className="w-100" src={blogDetails?.blogImage?.url} alt="" />
												</div>
												<div className="post-meta">
													<ul className="p-0">
														<li>
															<h5 className="author" style={{ color: screenTheme ? screenTheme?.blogTags : "" }}>
																{blogDetails?.authorName}
															</h5>
														</li>
														{blogDetails?.effectiveDate &&
															<li>
																<h5 className="author" style={{ color: screenTheme ? screenTheme?.blogTags : "" }}>
																	{moment(blogDetails.effectiveDate).format("MMM D, YYYY")}
																</h5>
															</li>
														}
														<li>
															<h5 className="tags" style={{ color: screenTheme ? screenTheme?.blogTags : "" }}>
																{blog?.ContentTagDTO?.[0]?.TagDTO?.Name}
															</h5>
														</li>
													</ul>
												</div>
												<div className="post-content">
													<h3 className="title" style={{ color: screenTheme ? screenTheme?.title : "" }}>
														<a>{blogDetails?.title}</a>
														<div className='subtitle' style={{ color: screenTheme ? screenTheme?.subTitle : "" }}>{blogDetails?.subTitle}</div>
													</h3>
													<div className='description' style={{ color: screenTheme ? screenTheme?.description : "" }} dangerouslySetInnerHTML={{ __html: blogDetails?.description }} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Layout>
			</SearchContextProvider>
		</MenuContextProvider>
	);
};

export default CommonBlog;
