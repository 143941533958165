import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TerrainCard from "@/components/epics/terrainCard";
import { isEmpty } from "lodash";

const Terrain = ({ zoneContent, className }) => {
  const [sectionData] = zoneContent || [];
  const terrainData = JSON.parse(sectionData?.ContentDTO?.ContentObject || "{}");

  return (
    <>
      {!isEmpty(terrainData) && (
        <section className={`service_section commonSection ${className}`}>
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                {terrainData?.subTitle && (
                  <h4 className="sectionSubTitle red_color">
                    {terrainData?.subTitle}
                  </h4>
                )}
                {terrainData?.title && (
                  <h2 className="sectionTitle white">{terrainData?.title}</h2>
                )}
                {terrainData?.description && (
                  <p className="sectionDescription color_aaa">
                    {terrainData?.description}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="custom_column">
              {terrainData.images &&
                terrainData?.images?.map((item, index) => (
                  <Col key={index} lg={3} md={4} sm={6} xs={12}>
                    <TerrainCard data={item} />
                  </Col>
                ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Terrain;
