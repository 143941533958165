import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectFade } from 'swiper/modules';
import "swiper/css/effect-fade";
import "swiper/css/navigation";

const SliderOne = ({ zoneContent }) => {
  const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)

  return (
    <section className="main-slider">
      <Swiper
        slidesPerView={1}
        loop={true}
        effect={'fade'}
        navigation={{
          nextEl: "#main-slider-next",
          prevEl: "#main-slider-prev",
        }}
        modules={[Autoplay, Navigation, EffectFade]}
      autoplay={{ delay: 10000 }}
      >
        {sectionContentObj && sectionContentObj.images && sectionContentObj.images.map((value, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-layer"
              style={{
                backgroundImage: `url(${value && value.url ? value.url : ""})`
              }}
            ></div>
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  {value?.subTitle && <p className="main-slider__subtext">{value?.subTitle}</p>}
                  {value?.title && <h3 className="main-slider__title">{value?.title}</h3>}
                  {value?.buttonText && <Link to={value?.href} className={`common_btn`}>
                    <span>{value?.buttonText || 'Button'}</span>
                  </Link>}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
      </Swiper>
    </section>
  );
};

export default SliderOne;
