import React, { createContext, useState } from "react";
const initialState = {};
export const MenuContext = createContext(initialState);

const MenuContextProvider = ({ children }) => {
  const [menuStatus, setMenuStatus] = useState(false);
  const [script, setScript] = useState('')
  const [reforgedMenuStatus, setReforgedMenuStatus] = useState(false);
  const updateMenuStatus = newStatus => {
    setMenuStatus(newStatus);
  };
  const addScript = newStatus => {
    setScript(newStatus);
  };
  const updateReforgedMenuStatus = newStatus => {
    setReforgedMenuStatus(newStatus);
  };
  return (
    <MenuContext.Provider value={{ menuStatus, updateMenuStatus, reforgedMenuStatus, updateReforgedMenuStatus, script, addScript }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;
