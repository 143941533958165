import React from "react";
import { Link } from "gatsby";

const LogoGallery = ({ data }) => {
  const { url, image, title } = data;
  return (
    <Link className="logoGallery text-center" to={url || ''}>
      <div className="flipper">
        <div className="front">
          <img src={image} alt={title} />
          <h3>{title}</h3>
        </div>
        <div className="back">
          <img src={image} alt={title} />
          <h3>{title}</h3>
        </div>
      </div>
    </Link>
  );
};

export default LogoGallery;
