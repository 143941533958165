import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { isEmpty } from 'lodash';
import LogoGallery from "@/components/epics/logoGallery";
import axios from "axios";

const ProductLines = ({ zoneContent }) => {
  const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
  const [epicData, setEpicData] = useState({});

  useEffect(() => {
    if (sectionContentObj) {
      if (!isEmpty(sectionContentObj)) {
        let epicsData = {
          title: sectionContentObj?.title,
          subTitle: sectionContentObj?.subTitle,
          description: sectionContentObj?.description,
        }
        if (sectionContentObj?.images?.length) {
          let postObj = [];
          sectionContentObj?.images.forEach((value) => {
            postObj.push({ title: value?.name, image: value?.url, url: value?.href });
          });
          epicsData.posts = postObj
        }
        setEpicData(epicsData)
      }
    }
  }, []);

  return (
    <>
      {
        !isEmpty(epicData) && (
          <section className="productLines commonSection">
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  {epicData?.subTitle && <h4 className="sectionSubTitle red_color">{epicData?.subTitle}</h4>}
                  {epicData?.title && <h2 className="sectionTitle white">{epicData?.title}</h2>}
                  {epicData?.description && <p className="sectionDescription color_aaa">{epicData?.description}</p>}
                </Col>
              </Row>
              <Row>
                {epicData?.posts && epicData?.posts.map((data, index) => (
                  <Col key={index} lg={3} sm={6}>
                    <LogoGallery data={data} />
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        )
      }
    </>
  );
};

export default ProductLines;
