import logoLight from "@/images/logoWOGD-Bright-No-Shadow.png";
import logoDark from "@/images/logo2.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
  largeLogo: logoLight,
  smallLogo: logoDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    subItems: [
      {
        name: "About Us",
        url: "/about",
      },
      {
        name: "Contact Us",
        url: "/contact",
      },      
    ],    
  },
  {
    name: "Epics",
    url: "/",
    subItems: [
      {
        name: "Dungeon Epics",
        url: "/epics/dungeon",
      },
      {
        name: "Dwarven Epics",
        url: "/epics/dwarven",
      },
      {
        name: "Mayan Epics",
        url: "/epics/mayan",
      },
      {
        name: "Cthulhu Epics",
        url: "/epics/cthulhu",
      },
      {
        name: "Sci-Fi Epics",
        url: "/epics/scifi",
      },
      {
        name: "World War Epics",
        url: "/epics/worldwar",
      },        
    ],
  },
  {
    name: "Creators",
    url: "/creators",
    subItems: [
      {
        name: "Writers",
        url: "/creators/writer",
      },
      {
        name: "Artists",
        url: "/creators/artist",
      },
      {
        name: "Cartographers",
        url: "/creators/cartographer",
      },
      {
        name: "Mini Sculptors",
        url: "/creators/sculptor",
      },
      {
        name: "Terrain Crafters",
        url: "/creators/crafter",
      },
      // {
      //   name: "Creator Portal",
      //   url: "/service-details",
      // },
    ],
  },
  // {
  //   name: "Updates",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Latest News",
  //       url: "/blog",
  //     },
  //     {
  //       name: "News Highlights",
  //       url: "/blog-2",
  //     },
  //     {
  //       name: "Newspaper",
  //       url: "/blog-3",
  //     },
  //     {
  //       name: "News Story",
  //       url: "/blog-single",
  //     },
  //   ],
  // },
  {
    name: "Forum",
    url: "http://forum.wogd.com",
    target: "_blank",
  },
  {
    name: "RPG Store",
    url: "https://store.wogd.com/collections/rpg-store",
    target: "_blank",
  },
];


import contactUsHeaderBackgroundImage from "@/images/about/bgDungeonEpics.jpg";

export const ContactUsData = 
{
  backgroundImage: contactUsHeaderBackgroundImage,
  smallTitle: "",
  largeTitle: "Contact Us",
  button: {
    label: "Contact Us",
    url: "/contact",
  },
};

import aboutUsHeaderBackgroundImage from "@/images/about/bgDungeonEpicsPaladinInHell.jpg";

export const AboutUsData = 
{
  backgroundImage: aboutUsHeaderBackgroundImage,
  smallTitle: "",
  largeTitle: "World of Game Design",
  button: {
    label: "About Us",
    url: "/about",
  },
};

import dwarvenEpicsHeader from "@/images/epics/dwarven/bgDwarvenEpics.jpg";

export const DwarvenEpicsData = 
{
  backgroundImage: dwarvenEpicsHeader,
  smallTitle: "",
  largeTitle: "Dwarven Epics",
  button: {
    label: "About Us",
    url: "/about",
  },
};


import sliderOne01 from "@/images/slider/1_1.jpg";
import sliderOne02 from "@/images/slider/1_2.jpg";
import sliderOne03 from "@/images/slider/1_3.jpg";
import sliderOne04 from "@/images/slider/1_4.jpg";
import sliderOne05 from "@/images/slider/1_5.jpg";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "Pixen Intrigue",
    title: "Diminutive \n Adventures",
    button: {
      label: "Enter the Realm",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "Cenote Emergence",
    title: "Depart The \n Underworld",
    button: {
      label: "Emerge Now",
      url: "/about",
    },
  },
  {
    image: sliderOne03,
    subTitle: "Taloc: Mayan",
    title: "Apocalyptic \n Adventure",
    button: {
      label: "Take up the Fight",
      url: "/about",
    },
  },
  {
    image: sliderOne04,
    subTitle: "Visceral Lair",
    title: "Enter the \n Beast",
    button: {
      label: "Explore Today",
      url: "/about",
    },
  },
  {
    image: sliderOne05,
    subTitle: "Delverr's Keep",
    title: "Conquer the \n Below",
    button: {
      label: "Delve Now",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "Our Warehouses",
  title: "Shipping Locations",
  description:
    "For returns or shipments please use the following addresses",
};

export const ContactInfosList = [
  {
    title: "Houston Deliveries",
    infos: [
      {
        name: "2951 Marina Bay Drive",
      },
      {
        name: "Suite #130-208",
      },
      {
        name: "League City, TX 77573",
      },
      {
        name: "j@wogd.com",
      },
      {
        name: "346.360.5146",
      },
      {
        name: "Loading Dock is Available",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact Us",
  title: "Leave us a Comment",
  description:
    "We are eager to hear from you so please leave us a message.",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "Tezca Jaguar Temple",
    date: "May 1",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Aether Screw Adventure",
    date: "May 5",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Base Mayan Tiles",
    date: "April 15",
    url: "/blog-single",
    image: blogImage3,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dig into the development of the Taloc: Mayan setting.",
    date: "May 5",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "What is Temporality?",
    date: "May 15",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Cthulhu Epics - The Siege of Kut",
    date: "May 30",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "Delverr's Keep - a Dwarven Epics Adventure",
    date: "June 5",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "EMP Shockwave",
    date: "June 15",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Montkelian - the Capital of Aurania",
    date: "June 20",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "World War Epics - The Trench",
    date: "June 25",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "Taloc: Mayan Kickstarter",
  title: "Latest Updates",
  text:
    "Our kickstarter is continuing production as we complete all tile casting and work to complete the books to send them to the publisher.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "Tezca Jaguar Temple",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "Aether Screw Adventure",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "Base Mayan Tiles",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "Jared Nielsen",
    data: "May 15, 2021 - 4:00 pm",
    content:
      "Creator, writer and sculptor of the Epics product line",
  },
  {
    image: commentImage2,
    name: "Matsya Das",
    data: "May 15, 2021 - 4:00 pm",
    content:
      "Artist and Illustrator for World of Game Design",
  },
];

import portfolio01 from "@/images/epics/mayan/publications/kukulTemple/1.jpg";
import portfolio02 from "@/images/epics/mayan/publications/kukulTemple/2.jpg";
import portfolio03 from "@/images/epics/mayan/publications/kukulTemple/3.jpg";
import portfolio04 from "@/images/epics/mayan/publications/kukulTemple/4.jpg";
import portfolio05 from "@/images/epics/mayan/publications/kukulTemple/5.jpg";
import portfolio06 from "@/images/epics/mayan/publications/kukulTemple/6.jpg";
import portfolio07 from "@/images/epics/mayan/publications/kukulTemple/7.jpg";
import portfolio08 from "@/images/epics/mayan/publications/kukulTemple/8.jpg";
import portfolio09 from "@/images/epics/mayan/publications/kukulTemple/9.jpg";
import portfolio10 from "@/images/epics/mayan/publications/kukulTemple/10.jpg";

export const PortfolioData = [
  {
    title: "Kukul Throne Room",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Ball Court",
    categories: ["all", "mayan", "kukul", "culture"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Caiman Room",
    categories: ["all", "mayan", "kukul", "creatures"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Calendric Glyph",
    categories: ["all", "mayan", "kukul", "stelae"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Cenote",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Heavens Gate",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio06,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Portal",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio07,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Stone Statues",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio08,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Stone Stelae",
    categories: ["all", "mayan", "kukul", "stelae"],
    image: portfolio09,
    url: "/portfolio-details",
  },
  {
    title: "Kukul Temple Entrance",
    categories: ["all", "mayan", "kukul", "buildings"],
    image: portfolio10,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Portfolio",
  text:
    "Explore the art and imagery from your favorite artists",
  client: "Matsya Das",
  categories: [
    {
      name: "Graphic,",
      url: "javascript:;",
    },
    {
      name: "Illustrations,",
      url: "javascript:;",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "javascript:;",
    },
    {
      name: "Twitter",
      url: "javascript:;",
    },
    {
      name: "Linkedin",
      url: "javascript:;",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Epic Adventures",
    text:
      "With the latest writers, artists and designers, we create fun games you can play at home or online",
    iconName: "theme-settings",
    url: "/service-details",
  },
  {
    title: "Custom Sculpts",
    text:
      "Handcrafted terrain pieces sculpted by Jared Nielsen can augment your gaming table",
    iconName: "theme-transfer",
    url: "/service-details",
  },
  {
    title: "Online Games",
    text:
      "Enjoy our games online with our VTT support and our online gaming initiatives",
    iconName: "theme-pie-chart",
    url: "/service-details",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to the World of Game Design",
  title: "We create epic stories and adventures that you can explore and contribute to.",
  text:
    "Whether you are an artist, writer, sculptor, or collector,\n we have exciting projects and worlds for you to explore.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We provide opportunities to top creators to bring you the most immersive adventures.",
};

import epicLogo001 from "@/images/epics/dungeon/homeLogoDungeonEpics_210.png";
import epicLogo002 from "@/images/epics/dwarven/homeLogoDwarvenEpics_210.png";
import epicLogo003 from "@/images/epics/mayan/homeLogoMayanEpics_210.png";
import epicLogo004 from "@/images/epics/cthulhu/homeLogoCthulhuEpics_210.png";
import epicLogo005 from "@/images/epics/scifi/homeLogoSciFiEpics_210.png";
import epicLogo006 from "@/images/epics/worldwar/homeLogoWorldWarEpics_210.png";

export const ProductLinesData = {
  sectionContent: {
    title: "We create epic stories and adventures that you can explore at home or online.",
    subTitle: "What is the World of Game Design?",
    text:
      "Our worlds are the creation of Jared Nielsen, who sculpts, worldbuilds and partners with other superbly talented creators to bring you these epic tales of adventure, intrigue, and romance.",
  },
  posts: [
    {
      title: "Dungeon Epics",
      image: epicLogo001,
      url: "/epics/dungeon",
    },
    {
      title: "Dwarven Epics",
      image: epicLogo002,
      url: "/epics/dwarven",
    },
    {
      title: "Mayan Epics",
      image: epicLogo003,
      url: "/epics/mayan",
    },
    {
      title: "Cthulhu Epics",
      image: epicLogo004,
      url: "/epics/cthulhu",
    },
    {
      title: "SciFi Epics",
      image: epicLogo005,
      url: "/epics/scifi",
    },
    {
      title: "World War Epics",
      image: epicLogo006,
      url: "/epics/worldwar",
    },
  ],
};


export const ServicePostTwoData = {
  sectionContent: {
    title: "We create epic stories and adventures that you can explore at home or online.",
    subTitle: "What is the World of Game Design?",
    text:
      "Our worlds are the creation of Jared Nielsen, who sculpts, worldbuilds and partners with other superbly talented creators to bring you these epic tales of adventure, intrigue, and romance.",
  },
  posts: [
    {
      title: "Dungeon Epics",
      iconName: "theme-web-design",
      url: "/epics/dungeon",
    },
    {
      title: "Mayan Epics",
      iconName: "theme-computer-graphic",
      url: "/epics/mayan",
    },
    {
      title: "Cthulhu Epics",
      iconName: "theme-development-1",
      url: "/epics/cthulhu",
    },
    {
      title: "SciFi Epics",
      iconName: "theme-development",
      url: "/epics/scifi",
    },
    {
      title: "World War Epics",
      iconName: "theme-app-development",
      url: "/epics/worldwar",
    },
  ],
};

import serviceOne01 from "@/images/home_1/6.jpg";
import serviceOne02 from "@/images/home_1/7.jpg";
import serviceOne03 from "@/images/home_1/8.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Become a Creator",
    subTitle: "Join the Team",
    text:
      "We are always interested in partnering with the right creative talent.",
  },
  posts: [
    {
      title: "Artists",
      image: serviceOne01,
      url: "/service-details",
    },
    {
      title: "Writers",
      image: serviceOne02,
      url: "/service-details",
    },
    {
      title: "Sculptors",
      image: serviceOne03,
      url: "/service-details",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We provide opportunities to top creators to bring you the most immersive adventures.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

import aboutOne01 from "@/images/about/1.jpg";
import aboutOne02 from "@/images/about/2.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "explore epic adventures",
    subTitle: "join in the fun!",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Publishing Since",
    number: 1997,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "Veteran Artisans of their Craft",
    subTitle: "meet the team",
    text:
      "Our core team of creators make the World of Game Design project happen.",
  },
  posts: [
    {
      image: team01,
      name: "Jared Nielsen",
      designation: "Worldbuilder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "https://facebook.com/wogdesign",
        },
        {
          name: "Twitter",
          url: "https://twitter.com/",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Matsya Das",
      designation: "Illustrator",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Zac Goins",
      designation: "Creative Director",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Troy Sandlin",
      designation: "Community Fulfillment Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Tim Kask",
      designation: "Writer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Merle Rasmussen",
      designation: "Writer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "James M. Ward",
      designation: "Writer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Chris Clark",
      designation: "Writer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/YouTubeImage.png";

export const VideoOneData = {
  sectionContent: {
    title: "We design old school games for tabletop, solo play, and online",
    subTitle: "Immersive Stories",
    text:
      "Each story is unique and blends modern technologies with that old school feel.  With a high emphasis on storytelling and full immersion we think you'll enjoy our games.",
  },
  video: {
    image: video01,
    ID: "Bc3Cn8DwqHo",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe",
    subTitle: "Get a free adventure every week",
  },
};

import testimonial01 from "@/images/home_1/t1.jpg";
import testimonial02 from "@/images/home_1/t2.jpg";
import testimonial03 from "@/images/home_1/t3.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "What we design",
    title: "Our Products",
  },
  posts: [
    {
      name: "Epic RPGs",
      designation: "Industry Veterans",
      image: testimonial01,
      content:
        "With industry veterans like James M. Ward, Merle Rasmussen, Tim Kask, Jared Nielsen, Chris Clark, and others, we can produce classic, old-school entertainment for the gaming public.",
      date: "",
    },
    {
      name: "Low Wall Terrain",
      designation: "Hand Sculpted",
      image: testimonial02,
      content:
        "Our terrain is sculpted by hand in clay or zBrush with expert paint jobs by some of the top painters in the industry.  At our convention events you can hand-craft your own 'Pour-A-Dungeon' terrain",
      date: "",
      },
    {
      name: "Board Games",
      designation: "Terrain and Minis",
      image: testimonial03,
      content:
        "Our AdventureHex line of storytelling board games are a fun addition to miniature gaming tables as well as solo gamers who enjoy the tactile feel of resin terrain and boardgames at home.",
      date: "",
      },
    {
      name: "Dice and Towers",
      designation: "Handcrafted Resin",
      image: testimonial01,
      content:
        "We manufacture and resell traditional and custom dice that are sourced from all over the world. We complement these with handmade dice boxes and hand crafted resin dice towers that even include fog machines.",
      date: "",
    },
    {
      name: "VTT Assets",
      designation: "Digital RPG Tools",
      image: testimonial02,
      content:
        "We convert many of our physical RPG and terrain properties into virtual tabletop (VTT) assets that can be used with the latest digital gaming software.",
      date: "",
    },
    {
      name: "Collector Tools",
      designation: "Mobile Applications",
      image: testimonial02,
      content:
        "Using the latest technologies, we create iOS and Android applications that allow collectors to track and store their collections using their mobile devices.",
      date: "",
    },    
  ],
};

import featureTab01 from "@/images/home_1/c1.jpg";
import featureTab02 from "@/images/home_1/c2.jpg";
import featureTab03 from "@/images/home_1/c3.jpg";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "We provide opportunities to top creators to bring you the most immersive adventures.",
  },
  posts: [
    {
      title: "Latest technology",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "Awesome Support",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab02,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "1 Click demo install",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab03,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "theme-team",
  title: "Water drips from vines clinging to the edge of the cenote rim, bringing sustenance to the vibrant plants all around, and perhaps something ... more ...",
  specialText: "",
  text:
    "Emerge from the cenote as your soul exits the underworld.  Your memory is wiped clean, but for what purpose?  You are accompanied by several others who also have no memory of their lives before.  Do you befriend them or do you fight?",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "Kukul Temple",
    subTitle: "Featured Portfolio",
    text:
      "The Kukul forged temples throughout their realm, but they can only defend and protect so many at once.  This Kukul Temple has fallen into disrepair and some intrepid trespassers have now entered its bowels.  What will they decide once within?",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "We design epic stories and adventures",
    subTitle: "welcome to the World of Game Design",
    text:
      "Whether you want to explore the deepest dungeons, or decipher the temporal mysteries of the ancient Maya, or experience the brutality of world conflict, we have stories that will engage and immerse you in worlds and situations that are thought provoking and entertaining.",
  },
  video: {
    title: "Watch Video",
    ID: "DwpdAgWrwwA",
    image: video02,
  },
};

import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "Join as a creator",
    subTitle: "Become part of the fun",
    text:
      "Our platform is designed to let you weave your own stories and immersive environments right here in our design studio.  If you're interested in joining in collaborative storytelling just create an account and join in!",
  },
  button: {
    label: "Coming Soon...",
    url: "javascript:;",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      "We provide opportunities to top creators to bring you the most immersive adventures.",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Request a free quote",
    url: "/contact",
  },
];
