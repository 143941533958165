import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import TeamCard from "@/components/team-card";
import SectionTitle from "@/components/section-title";
import "swiper/css"
import 'swiper/css/pagination';

const TeamCarousel = ({ zoneContent }) => {
  const [sectionData] = zoneContent || [];
  const [teamCarouseldata, setTeamCarouseldata] = useState();

  useEffect(() => {
    if (sectionData) {
      let teamContent = sectionData?.ContentDTO?.ContentObject && JSON.parse(sectionData?.ContentDTO?.ContentObject || "{}");
      if (teamContent) {
        let sectionData = {
          title: teamContent.title,
          subTitle: teamContent.subTitle,
          text: teamContent.description,
        };

        let imageData = [];
        teamContent.images?.map(value => {
          imageData.push({
            name: value.name,
            designation: value.designation,
            image: value.url,
            sortOrder: value.sortOrder,
          });
        });
        sectionData.cardDetails = imageData.sort(
          (valueOne, valueTwo) => valueOne.sortOrder - valueTwo.sortOrder
        );
        setTeamCarouseldata(sectionData);
      }
    }
  }, [sectionData]);

  return (
    <>
      {teamCarouseldata && (
        <section className="commonSection team">
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                <SectionTitle data={teamCarouseldata} />
              </Col>
            </Row>
          </Container>
          <Swiper className="team_slider"
            spaceBetween={0}
            slidesPerView={1}
            pagination={{
              el: "#team-carousel-pagination",
              type: "bullets",
              clickable: true,
            }}
            breakpoints={{
              0: {
                spaceBetween: 0,
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              576: {
                spaceBetween: 30,
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1200: {
                spaceBetween: 30,
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
            }}
            modules={[Pagination]}>
            {teamCarouseldata.cardDetails.map((team, index) => {
              return (
                <SwiperSlide key={index}>
                  <TeamCard data={team} />
                </SwiperSlide>
              );
            })}
            <div
              className="swiper-pagination"
              id="team-carousel-pagination"
            ></div>
          </Swiper>
        </section>
      )}
    </>
  );
};

export default TeamCarousel;
