import { RESPONSE_TAG } from "@/services/tagFile";
import { errorToast, successToast } from "./toast";
import { navigate } from 'gatsby'

const CustomFormSubmit = async (
  values,
  formData,
  addRecord,
  setSuccessHandler,
  successHandler,
  userInfo,
  responseTypeId,
  emailSubject
) => {
  let fromEmail = Object.keys(values).find(val => val.includes("email"));
  let idHandler;
  await addRecord({
    entity: "Response",
    tag: RESPONSE_TAG,
    data: {
      SurveyId: formData && formData.survey.id,
      Name: formData && formData.survey.name,
      ResponseTypeId: responseTypeId,
      PlaceholderText: formData && formData.survey.description,
      Description: formData && formData.survey.description,
    },
  }).then(res => {
    idHandler = res?.data?.Id;
  });

  const postFormData = async (values, idHandler) => {
    let questionName = Object.values(values);
    let surveyResponse = {};
    let surveyAnswer = [];
    questionName.forEach((fields, i) => {
      if (typeof fields === "object" && fields.length > 0) {
        fields.map(val => {
          surveyResponse = {
            name: val.optionName,
            responseId: idHandler,
            questionId: val.questionId,
            description: "",
            answerTypeId: val.questionTypeId,
          };
          surveyAnswer.push(surveyResponse);
          return surveyResponse;
        });
      } else {
        surveyResponse = {
          name: fields,
          responseId: idHandler,
          questionId: formData.questions[i] && formData.questions[i].id,
          description: "",
          answerTypeId:
            formData.questions[i] && formData.questions[i].questionType.id,
        };
        surveyAnswer.push(surveyResponse);
        return surveyResponse;
      }
    });
    await addRecord({
      entity: "Answer/CreateAnswers",
      data: surveyAnswer,
    }).then(res => {
      if (res.error) {
        errorToast("Error in submitting data.");
        setSuccessHandler(false);
      }
      let choiceResponse = [];
      const idHandler = async (OId, QId, res, optionName) => {
        res &&
          res.data &&
          res.data.find(finding => {
            if (finding.questionId == QId && finding.name === optionName) {
              const { id, answerTypeId } = finding;
              choiceResponse.push({
                optionId: OId,
                answerId: id,
                choiceTypeId: answerTypeId,
                name: optionName,
              });
            }
          });
      };
      Object.values(values).find(del => {
        if (Array.isArray(del)) {
          del.forEach(data => {
            idHandler(data.optionId, data.questionId, res, data.optionName);
          });
        }
      });
      addRecord({ entity: "Choice/CreateChoices", data: choiceResponse }).then(
        res => {
          if (res.error) {
            errorToast("Error in submitting data.");
            setSuccessHandler(false);
          }
        }
      );
    });
    addRecord({
      entity: "EntityResponse",
      data: { EntityId: 1, ResponseId: idHandler, EntityResponseTypeId: 1 },
    }).then(res => {
      if (res.error) {
        errorToast("Error in submitting data.");
        setSuccessHandler(false);
      }
    });
    addRecord({
      entity: "EntityResponse/SendEmail",
      data: {
        EntityId: 1,
        ResponseId: idHandler,
        SurveyId: formData && formData.survey.id,
        Subject: emailSubject,
        FromEmail: values[fromEmail],
        ...userInfo,
      },
    }).then(res => {
      if (res.isSent) {
        successToast(res.response);
        setSuccessHandler(false);
      } else {
        errorToast(res.response);
        setSuccessHandler(false);
      }
      navigate("/")
    });
    if (successHandler) {
      successToast("Form Data submitted successfully.");
    }
  };
  postFormData(values, idHandler);
};

export default CustomFormSubmit;
