import React from 'react'

const JoinNow = ({ zoneContent }) => {
    const [sectionData] = zoneContent || [];
    const publicationData = JSON.parse(
        sectionData?.ContentDTO?.ContentObject || "{}"
    );

    return (
        <div className='d-flex justify-content-center'>
            <img src={publicationData?.images[0]?.url} alt={publicationData?.images[0]?.name} width={'100%'} />
        </div>
    )
}

export default JoinNow