import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChannelCard from "@/components/epics/channelCard";

const Channels = ({ zoneContent }) => {
  const channelData = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)

  return (
    <>
      {channelData?.lists?.length > 0 && (
        <section className="service_section_2 commonSection">
          <Container>
            <Row>
              {channelData.lists.map((post, index) => (
                <Col lg={4} md={12} key={index}>
                  <ChannelCard data={post} />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Channels;
