import React from 'react'
import { Modal } from 'reactstrap'

const ImageModal = ({isOpen, setOpen, size, className, message}) => {
  return (
    <Modal
        isOpen={isOpen}
        toggle={setOpen}
        size={size}
        centered
        className={className}
    >
      <button onClick={setOpen} class="modal-video-close-btn" aria-label="Close the modal by clicking here"></button>
      <div className='d-flex flex-column align-items-center '>
            <img src={message?.url || ""} alt='img' style={{ height: "100%", width: "100%" }} />
            <h4 style={{textShadow : "0px 0px 5px #fff, 0px 0px 8px #fff, 0px 0px 10px #fff", letterSpacing : "2px"}}>{message?.name || ""}</h4>
        </div>
    </Modal>
  )
}

export default ImageModal
