import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import PortfolioCard from "@/components/epics/portfolioCard";

const Portfolio = ({ zoneContent }) => {
  const [portfolioDetails, setPortfolioDetails] = useState({})
  useEffect(() => {
    const sectionContent = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    if (!isEmpty(sectionContent)) {
      let portfolioSectionData = {
        title: sectionContent?.title,
        subTitle: sectionContent?.subTitle,
        description: sectionContent?.description,
      }
      if (sectionContent?.images?.length) {
        let portfolioPost = [];
        sectionContent.images.forEach((value) => {
          portfolioPost.push({ title: value?.name, url: value?.redirectURL, categories: value?.description && value?.description.split(","), image: value?.url });
        });
        portfolioSectionData.portfolioPosts = portfolioPost
      }
      setPortfolioDetails(portfolioSectionData)
    }
  }, []);

  return (
    <>
      {!isEmpty(portfolioDetails) &&
        <section className="commonSection portfolio">
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                {portfolioDetails.subTitle && <h4 className="sectionSubTitle">{portfolioDetails?.subTitle}</h4>}
                {portfolioDetails.title && <h2 className="sectionSubTitle">{portfolioDetails?.title}</h2>}
                {portfolioDetails.description && <p className="sectionDescription">{portfolioDetails?.description}</p>}
              </Col>
            </Row>
            <Row id="Grid">
              <div className="custom">
                <Row>
                  {portfolioDetails.portfolioPosts && portfolioDetails.portfolioPosts.map((item, index) => (
                    <Col lg={4} md={6} sm={12} key={index}>
                      <PortfolioCard data={item} />
                    </Col>
                  ))}
                </Row>
              </div>
            </Row>
          </Container>
        </section>
      }</>
  );
};
export default Portfolio;
