import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Pagination } from 'swiper/modules';
import ShopifyHeader from "./shopify-header";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import Layout from "./layout";
import Breadcrumb from "./breadcrumb";
import defaultShopifyImage from "../assets/images/shopify-bag-icon.png";
import '@/css/zweihander.css'
import "swiper/css"
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../assets/css/shopify-product-details.css";

const ShopifyProductDetails = ({ pageContext: { applicationScreensData, product, topProducts = [] } }) => {
    const applicationDetails = { applicationScreen: applicationScreensData };
    const [isLongWeb, setIsLongWeb] = useState(null)
    const [zones, setZones] = useState([]);
    const [menuData, setMenuData] = useState([]);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [commonDetail, setCommonDetail] = useState();
    const [screens, setScreens] = useState([]);
    const [screenName, setScreenName] = useState({});
    const [reforgedHeader, setReforgedHeader] = useState([]);
    const ref = useRef("")
    const location = (typeof window !== 'undefined') && window.location;
    const menuContentObj = reforgedHeader?.length ? JSON.parse(reforgedHeader?.[0]?.MenuDTO?.ContentObject) : null
    const createdRoute = zones?.ScreenDTO?.Path?.split("/")?.[1]?.replace(/-/g, "_")?.toLowerCase() || '';
    const isLongWebAddress = applicationDetails?.applicationScreen?.screenMapping?.find(item =>
        location?.origin === item?.LongWebAddress ||
        location?.origin?.replace("//www.", "//") === item?.LongWebAddress ||
        location?.hostname === item?.LongWebAddress ||
        "www." + location?.hostname === item?.LongWebAddress
    )
    useEffect(() => {
        setIsLongWeb(isLongWebAddress)
        setMenuData(applicationDetails?.applicationScreen?.menuData);
        const screenMenu = applicationDetails?.applicationScreen?.reforgedMenu?.filter(menu => menu?.ScreenDTO?.Path?.toLowerCase() === `/${location?.pathname?.split('/')[1]?.toLowerCase()}`)
        if (isLongWebAddress) {
            setReforgedHeader(applicationDetails?.applicationScreen?.reforgedMenu?.filter(item => item?.ScreenDTO?.Name === isLongWebAddress?.ScreenDTO?.Name))
        } else {
            setReforgedHeader(screenMenu?.length ? screenMenu : []);
        }
        let screen = applicationDetails?.applicationScreen?.applicationScreen?.map(
            value => value?.ScreenDTO
        );
        setScreens(screen);
        const appDetails = applicationDetails?.applicationScreen?.applicationContent?.map(value => value?.ContentDTO);
        setCommonDetail(appDetails);

        const targetPath = isLongWebAddress?.ScreenDTO?.Path?.toLowerCase() || `/${location?.pathname?.split("/")?.[1]?.toLowerCase()}`;
        const filteredZones = applicationDetails?.applicationScreen?.screenContent?.find(value =>
            value?.ScreenDTO?.Path?.toLowerCase() === targetPath
        );
        setZones(filteredZones)
        setScreenName(filteredZones?.ScreenDTO);
        ref.current.innerHTML = product?.bodyHtml;
    }, [isLongWeb]);
    const breakpoint = {
        10:{
            slidesPerView:1,
        },
        300:{
            slidesPerView: 2,
            spaceBetween: 5,
        },
        400: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        500: {
          slidesPerView: 4,
          spaceBetween: 10
        },
    }
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle={screenName}>
                    {!isLongWeb && menuData?.length ? (
                        <HeaderOne
                            menuData={menuData}
                            headerDetail={commonDetail}
                            screens={screens} />
                    ) : ""}
                    <ShopifyHeader reforgedMenu={menuContentObj} isLongWebAddress={isLongWeb} />
                    <div className={isLongWeb ? "mt-3" : "top-spacing"}>
                        <div className="breadcrumbs-container d-flex align-items-center mb-3">
                            <Breadcrumb title={product?.title} />
                        </div>
                        <div className={`row justify-content-center w-100 `}>
                            <div className="col-11 col-lg-6 col-md-6 shopify-product-swiper">
                            {product?.images?.length ? <>
                                <Swiper
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[Thumbs, Pagination, Navigation]}
                                    className="mySwipper mb-3"
                                    loop
                                >
                                    {product?.images?.map((image, i) => (<SwiperSlide key={`Feature${i}`} className="image-slide"><img src={image?.src} className="img-fluid particular-product-image" /></SwiperSlide>))}
                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    breakpoints={breakpoint}
                                    resizeObserver={true}
                                    breakpointsBase={"container"}
                                    navigation={true}
                                    modules={[Navigation, Thumbs]}
                                    loop
                                    className="thumb-swiper"
                                >
                                    {product?.images?.map((image, i) => (
                                        <SwiperSlide key={`Feature${i}`} >
                                            <div className='thumb'>
                                                <img src={image?.src} width={"100%"} className="thumb-img container" />
                                            </div>
                                        </SwiperSlide>))
                                    }
                                </Swiper>
                            </> : <img src={defaultShopifyImage} className="img-fluid particular-product-image default-image" />}
                        </div>

                        <div className="col-10 col-lg-5 col-md-6">
                            <div className="product-title">{product?.title}</div>
                            <div className="badge bg-light text-gray border border-gray p-1 mr-2">{product?.vendor}</div>
                            <span className="badge bg-light text-primary border border-primary p-1">{product?.productType}</span>
                            <div className="product-tags">{product?.tags}</div>
                            <a className={`common_btn red_bg buy-now ${product?.publishedScope === "web" || product?.publishedAt === null ? "disabled" : ""}`} target="_blank" href={`https://store.wogd.com/products/${product?.handle}`}>
                                <span>{product?.publishedScope === "web" || product?.publishedAt === null ? "Not For Sale" : "Buy Now"}</span>
                            </a>
                            <hr />
                            <div className="product-description" ref={ref}></div>
                        </div>
                        </div>
                        <div className='line-block' />
                        {topProducts?.length ?
                            <div className="top-product-container">
                                <div className="heading-section">Top Most Products</div>
                                <div className="top-product-section">
                                    {topProducts?.map((values, index) =>
                                        <div key={index} className="top-product-details" onClick={() => navigate(`/${createdRoute?.replace(/_/g, "-")}/${values?.handle}`)}>
                                            <div className='product-img-wrapper'>
                                                <img src={values?.images?.[0]?.src || shopifyDefaultImage} alt="image" className={`product-img ${!values?.images?.length ? "default-image" : ""}`} />
                                            </div>
                                            <div>{values?.title}</div>
                                            <div className="badge bg-light text-gray border border-gray p-1 mr-2">{values?.vendor}</div>
                                            <div className="badge bg-light text-primary border border-primary p-1">{values?.productType}</div>
                                            <div className='text'>{values?.tags}</div>
                                        </div>)}
                                </div>
                            </div> : ""}
                    </div>
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default ShopifyProductDetails;
