import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import Pagination from './pagination';
import ShopifyHeader from './shopify-header';
import shopifyDefaultImage from "../assets/images/shopify-bag-icon.png";
import "../assets/css/shopify-product-list.css";

const recordsPerPage = 16;
const ShopifyProductList = ({ zoneContent, shopifyProductDetails, menuData, isLongWebAddress }) => {
    const reforgedMenu = menuData?.length && JSON.parse(menuData?.[0]?.MenuDTO?.ContentObject)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [shopifyProductsLists, setShopifyProductsLists] = useState([]);
    const [productTags, setProductTags] = useState([]);
    const [productVendors, setProductVendors] = useState([]);
    const [viewDetails, setViewDetails] = useState({
        isTag: true,
        isVendor: true
    })
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState([]);

    const currentRoute = location?.pathname?.split("/")?.[1]?.replace(/-/g, "_")?.toLowerCase() || '';
    const createdRoute = zoneContent?.[0]?.ScreenDTO?.Path?.split("/")?.[1]?.replace(/-/g, "_")?.toLowerCase() || '';
    const filteredData = shopifyProductDetails?.find(value => value?.hasOwnProperty(currentRoute));
    const shopifyProductData = !isLongWebAddress
        ? filteredData?.[currentRoute]
        : location?.pathname !== "/"
            ? filteredData?.[currentRoute]
            : shopifyProductDetails?.find(value => value?.hasOwnProperty(createdRoute))?.[createdRoute];

    const currentRecords = useMemo(() => {
        const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
        const indexOfLastRecord = indexOfFirstRecord + recordsPerPage;
        return shopifyProductsLists?.slice(indexOfFirstRecord, indexOfLastRecord);
    }, [currentPage, shopifyProductsLists])

    const FiltersItem = ({ filterName }) => {
        const isTagFilter = filterName === "tags";
        const itemList = isTagFilter ? productTags : productVendors;
        const displayedItems = viewDetails[isTagFilter ? 'isTag' : 'isVendor'] ? itemList?.length > 7 ? itemList?.slice(0, 7) : itemList : itemList;
        const linkText = viewDetails[isTagFilter ? 'isTag' : 'isVendor'] ? "view more" : "view less";

        const handleClick = () => {
            setViewDetails(prev => ({
                ...prev,
                [isTagFilter ? 'isTag' : 'isVendor']: !prev[isTagFilter ? 'isTag' : 'isVendor']
            }));
        };
        return (
            <div className='side-nav-collapse'>
                {displayedItems?.map((item, index) => (
                    <TagItem key={index} item={item} filterName={filterName} />
                ))}
                {itemList?.length > 7 && (
                    <a onClick={handleClick} className='show-more'>
                        {linkText}
                    </a>
                )}
            </div>
        );
    };

    const TagItem = ({ item, filterName }) => (
        <label htmlFor={filterName === "tags" ? `${item}-tag` : `${item}-vendor`} className='side-nav-item'>
            <input
                type='checkbox'
                id={filterName === "tags" ? `${item}-tag` : `${item}-vendor`}
                name={item}
                value={item}
                onChange={(e) => filterName === "tags" ? handleTagChecked(e) : handleVendorChecked(e)}
                checked={filterName === "tags" ? selectedTags?.includes(item) : selectedVendors?.includes(item)}
            />
            <div>{item}</div>
        </label>
    );

    const handleTagChecked = (e) => {
        const { value, checked } = e.target
        setSelectedTags((prevChecked) =>
            checked ? [...prevChecked, value] : prevChecked?.filter(tag => tag !== value)
        )
    }
    const handleVendorChecked = (e) => {
        const { value, checked } = e.target
        setSelectedVendors((prevChecked) =>
            checked ? [...prevChecked, value] : prevChecked?.filter(vendor => vendor !== value)
        )
    }

    const handleFiltersValue = () => {
        let validateTags = new Set();
        let validateVendor = new Set();
        shopifyProductData?.forEach(value => {
            if (value?.tags !== "") {
                value?.tags?.split(",")?.forEach(item => validateTags?.add(item?.trim()))
            }
            if (value?.vendor !== "") {
                validateVendor?.add(value?.vendor)
            }
        });
        setProductTags([...validateTags])
        setProductVendors([...validateVendor])
    }
    useEffect(() => {
        if (shopifyProductData?.length) {
            handleFiltersValue()
        }
    }, [shopifyProductData])

    useEffect(() => {
        setCurrentPage(1);
        const applyFilters = (shopifyProduct) => {
            return shopifyProduct?.filter(item => {
                const itemTags = item?.tags?.split(",")?.map(tag => tag?.trim());
                const tagsMatch = selectedTags?.length === 0 || selectedTags?.every(tag => itemTags?.includes(tag));
                const vendorMatch = selectedVendors?.length === 0 || selectedVendors?.includes(item?.vendor);
                const titleMatch = item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
                return tagsMatch && vendorMatch && titleMatch;
            });
        }
        if (shopifyProductData?.length) {
            if (searchInput || selectedTags?.length > 0 || selectedVendors?.length > 0) {
                const filteredProductData = applyFilters(shopifyProductData);
                setShopifyProductsLists(filteredProductData);
            } else {
                setShopifyProductsLists(shopifyProductData);
            }
        }
    }, [searchInput, selectedTags, selectedVendors, shopifyProductData])

    return (
        <div className={!isLongWebAddress ? "product-list-wrapper" : ""}>
            <ShopifyHeader reforgedMenu={reforgedMenu} isShopifyList setSearchInput={setSearchInput} isLongWebAddress={isLongWebAddress} />
            <div className="main-container">
                <div className='layout-row'>
                    <div className='side-nav'>
                        {productTags?.length > 0 ? <><div className="side-nav-heading">Tags</div><FiltersItem filterName="tags" /></> : ""}
                        {productVendors?.length > 0 ? <><div className="side-nav-heading mt-4">Vendor</div><FiltersItem /></> : ""}
                    </div>
                    <div className='product-top-area'>
                        <Pagination
                            totalData={shopifyProductsLists?.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            recordsPerPage={recordsPerPage}
                        />
                        {shopifyProductsLists?.length > recordsPerPage + 1 && <hr />}
                        <div className='products-container'>
                            <div className={`product-details ${shopifyProductsLists?.length < recordsPerPage + 1 && "disable-line"}`} >
                                {shopifyProductsLists?.length ? currentRecords?.map((values, index) => (
                                    <div className='product-info-wrapper' key={index} onClick={() => navigate(`/${createdRoute?.replace(/_/g, "-")}/${values?.handle}`)}>
                                        <div className='product-section'>
                                            <div className='product-img-wrapper'>
                                                <img src={values?.images?.[0]?.src || shopifyDefaultImage} alt="image" className={`product-img ${!values?.images?.length ? "default-image" : ""}`} /></div>
                                            <div>{values?.title}</div>
                                            <div className="badge bg-light text-gray border border-gray p-1 mr-2">{values?.vendor}</div>
                                            <div className="badge bg-light text-primary border border-primary p-1">{values?.productType}</div>
                                            <div className='text'>{values?.tags}</div>
                                        </div>
                                        <div className='line' />
                                    </div>)
                                ) : <div className='no-data'>No Found Data</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopifyProductList;
