import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import defaultImage from "../../assets/images/bg/5.jpg";

const ScenicQuote = ({ zoneContent }) => {
  const quoteData = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)

  const pageBannerStyle =
    quoteData?.backgroundImage?.url
      ? { backgroundImage: `url(${quoteData?.backgroundImage?.url})` }
      : { backgroundImage: `url(${defaultImage})` };

  return (
    <>
      {!isEmpty(quoteData) && (
        <section className="commonSection testimonial" style={pageBannerStyle}>
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }} sm={12} className="text-center">
                <div className="testimonial_content">
                  {quoteData?.title && <h2>{quoteData?.title}</h2>}
                  {quoteData?.description && <p>{quoteData?.description}</p>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ScenicQuote;
