import React from 'react'
import { Modal, ModalBody, ModalHeader, Input, Button, Spinner } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useAddRecordMutation } from '@/services/wogdApi';
import { successToast } from './publisher/surveyForm/toast';

const GetEmail = ({ isOpen, setIsOpen }) => {
    const [counterPartyActionLog , { isLoading } ] = useAddRecordMutation();

    const EmailSchema = Yup.object().shape({
      Email: Yup.string().email('Please enter a valid email address').required("Email is required.")
    })

    const submitFormAllValues = (values) => {
        const location = (typeof window !== 'undefined') && window.location;
        const actionLog = {
            email: values?.Email,
            targetURL: isOpen,
            refererURL: location?.href
        }
          counterPartyActionLog({
            entity: "CMSReference/ApplicationReferer",
            isWogdV2Api: true,
            data: actionLog
          }).then(res => {
            if (!res.error) {
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); 
                if (isSafari) {
                    window.location.href = isOpen;
                } else {
                    window.open(isOpen, "_blank", "noreferrer");
                }
                successToast("Email added...")
                setIsOpen(false)
            }
          })
    }
    return (
        <>
            <Modal isOpen={!!isOpen} toggle={setIsOpen} size="md">
                <ModalHeader>
                    <h5 className='m-0'>Add your email</h5>
                    <button 
                        onClick={() => setIsOpen(false)} 
                        style={{ 
                            border: 'none', 
                            outline: "none", 
                            background: 'none', 
                            color: '#818181', 
                            cursor: 'pointer', 
                            fontSize: '20px',
                            fontWeight: 'bolder'
                        }}
                    >
                        &Chi;
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            Email: ""
                        }}
                        validationSchema={EmailSchema}
                        onSubmit={submitFormAllValues}
                    >
                        {(formik) => {
                            const {
                                values, errors, touched, handleBlur, handleChange
                            } = formik;

                            return (
                                <Form>
                                    <Input
                                        label="Email"
                                        name="Email"
                                        type="email"
                                        placeholder="Enter Email"
                                        fieldValue={values?.Email}
                                        errors={errors?.Email}
                                        touched={touched?.Email}
                                        handleBlur={handleBlur}
                                        onChange={handleChange}
                                        className='email-collector'
                                    />
                                    {(touched?.Email && errors?.Email) && <font color="red">{errors.Email}</font>}
                                    <div className="mt-3 d-flex justify-content-end">
                                        <Button type="submit" className="d-flex align-items-center" disabled={isLoading}>
                                            {isLoading && <Spinner size="sm" color="light" style={{ height: "1rem", width: "1rem", marginRight: "6px" }} children="" />}
                                            Submit
                                        </Button>
                                    </div>
                                </Form>

                            )
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    )
}

export default GetEmail
