import React from 'react'
import usePagination, { DOTS } from '../hooks/usePagination';
import "../assets/css/pagination.css";

const Pagination = ({ totalData, currentPage, setCurrentPage, recordsPerPage }) => {
    const goToNextPage = () => {
        if (currentPage !== lastPage) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    const paginationRange = usePagination({ totalData, currentPage, recordsPerPage })
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }
    let lastPage = paginationRange?.length ? paginationRange[paginationRange.length - 1] : 1;
    return (
        <nav>
            <ul className='pagination-wrapper'>
                <li className="page-item">
                    <a className="page-link btn" onClick={goToPrevPage} href='#'> {"< "}</a>
                </li>
                {paginationRange?.map((pgNumber, index) => {
                    if (pgNumber === DOTS) {
                        return <li className="dots" key={index}>&#8230;</li>;
                    }
                    return (<li key={index}
                        className={`page-item ${currentPage == pgNumber ? 'active' : ''} `}>
                        <a onClick={() => setCurrentPage(pgNumber)} className='page-link' href='#'>
                            {pgNumber}
                        </a>
                    </li>)

                })}
                <li className="page-item">
                    <a className="page-link btn" onClick={goToNextPage} href='#'>
                        {">"}
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination