import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import _ from "lodash";
import Signature from "signature_pad";
import "./SignatureModal.css";

const SignatureModal = ({ isOpen, setIsOpen, setCurrentUserSign, currentUserSign, saveSign }) => {
  const [signaturePad, setSignaturePad] = useState();
  const [savedSignature, setSavedSignature] = useState();
  const [isMounted, setIsMounted] = useState(false);
  let signatureRedoArray = [];

  const handleSave = () => {
    setSavedSignature(signaturePad.toDataURL());
    setCurrentUserSign((prev) => { return { ...prev, "savedSignature": signaturePad.toDataURL() } })
    saveSign()
    setIsOpen()
  };
  const handleUndo = () => {
    let signatureRemovedData = [];
    let signatureData = signaturePad.toData();
    let signatureRedoData = _.cloneDeep(signatureData);

    if (signatureData.length > 0) {
      signatureData.pop();
      signaturePad.fromData(signatureData);
      signatureRemovedData = signatureRedoData[signatureRedoData.length - 1];
      signatureRedoArray.push(signatureRemovedData);
    }
  };
  const handleRedo = () => {
    if (signatureRedoArray.length !== 0) {
      let values = signaturePad.toData();
      let lastValue = signatureRedoArray[signatureRedoArray.length - 1];
      values.push(lastValue);
      signaturePad.fromData(values);
      signatureRedoArray.pop(lastValue);
    }
  };
  const handleClear = () => {
    signaturePad.clear();
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      const wrapper = document.getElementById("signature-pad");
      const canvas = wrapper.querySelector("canvas");
      canvas.getContext("2d").scale(1, 1);
      const tempSignaturePad = new Signature(canvas, {
        backgroundColor: "rgb(255, 255, 255)",
      });
      setSignaturePad(tempSignaturePad);
      setCurrentUserSign((prev) => { return { ...prev, "signaturePad": tempSignaturePad } })
    }

    return () => {
      if (signaturePad) {
        signaturePad.off();
      }
    };
  }, [isMounted]);

  useEffect(() => {
    if (currentUserSign.savedSignature) {
      setSignaturePad(currentUserSign.signaturePad)
      setSavedSignature(currentUserSign.savedSignature)
    }
  }, [])

  return (
    <>
      <Modal isOpen={!!isOpen} toggle={setIsOpen} size="lg">
        <ModalHeader toggle={setIsOpen} >
          Add your signature
        </ModalHeader>
        <ModalBody>
          <div className="App">
            <div id="signature-pad">
              <canvas className="signature-canvas-div"></canvas>
              <div className='sign-buttons'>
                <button className='btn btn-success' onClick={handleSave}> Save </button>
                <button className='btn btn-dark ' onClick={handleUndo}> Undo </button>
                <button className='btn btn-dark' onClick={handleRedo}> Redo </button>
                <button className='btn btn-danger' onClick={handleClear}> Clear </button>
              </div>
            </div>
            <div>
              <h3> Your Signature </h3>
              {savedSignature && (
                <img
                  className="signature-image"
                  alt="saved-signature"
                  src={savedSignature}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SignatureModal