import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import defaultImage from "../assets/images/bg/8.jpg";

const CallToActionOne = ({ buttonClass, zoneContent }) => {
  const [callToActionSection] = zoneContent || [];
  const comingSoonRecords = JSON.parse(callToActionSection?.ContentDTO?.ContentObject || "{}");
  const [comingSoonBackgroundImage] = comingSoonRecords?.images?.slice(-1) || [];
  const comingSoonStyle = comingSoonBackgroundImage
    ? {
      background: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(${comingSoonBackgroundImage?.url})`,
    }
    : {
      backgroundImage: `url(${defaultImage})`,
    };
  return (
    <section className={`commonSection`} style={comingSoonStyle}>
      <div className="container">
        <div className="row">
          {comingSoonRecords?.title && (
            <div className="col-lg-9 col-sm-8 col-md-9">
              <h2 className="sectionTitle white">{comingSoonRecords?.title}</h2>
            </div>
          )}
          {comingSoonRecords?.buttonText && (
            <div className="col-lg-3  col-sm-4 col-md-3 text-right">
              <Link
                to={comingSoonRecords?.redirectURL}
                className={`common_btn ${buttonClass}`}
              >
                <span>{comingSoonRecords?.buttonText}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CallToActionOne;
