import React, { useEffect, useState } from "react";

const LicensingLogos = ({ content }) => {
  const [imagesLists, setImageLists] = useState([]);

  useEffect(() => {
    const lists = content.images.map(image => {
      const getImageName = content.lists.filter(val => {
        if (Object.keys(val).includes("imageName")) {
          if (val.imageName === image.name) {
            return val;
          }
        }
      });
      return { ...image, ...getImageName[0] };
    });
    setImageLists(lists);
  }, []);

  return (
    <section className="my-md-5 my-4">
      <div className="logos">
        {imagesLists &&
          imagesLists.length &&
          imagesLists.map((image,i) => {
            return (
              <div key={`image_${i}`} className="licensing-logo">
                <img
                  src={image.url}
                  alt={image.imageName}
                  width="100%"
                  className="logo-img"
                />
                <h6 className="text-center pt-3 mb-0">{image.name}</h6>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default LicensingLogos;
