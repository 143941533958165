import React from "react";
import { Link } from "gatsby";

const PublicationCard = ({ data }) => {
  const { name, url, redirectURL = "#", buttonText, callToAction = "#" } = data;
  return (
    <div className="single_publication">
      <div className="single_publication">
        <Link to={redirectURL} target="_blank">
          {url && <img src={url} alt={name} />}
        </Link>
        {buttonText && (
          <Link
            to={callToAction}
            target="_blank"
            className={`common_btn`}
            style={
              callToAction === "#"
                ? { pointerEvents: "none", backgroundColor: "#cccccc" }
                : {}
            }
          >
            <span style={callToAction === "#" ? { color: "#666666" } : {}}>
              {buttonText}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PublicationCard;
