import React from "react";

const DungeonEpics = ({ zoneContent }) => {
  const dungeonEpicsContents = JSON.parse(
    zoneContent[0]?.ContentDTO?.ContentObject
  );
  
  return (
    <section className="py-5 d-flex justify-content-center ">
      <h2 style={{ marginTop: "20rem", color: "#777070", fontWeight: "1000" }}>
        {dungeonEpicsContents?.title}
      </h2>
    </section>
  );
};

export default DungeonEpics;
