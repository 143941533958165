import React, { useState, useEffect } from "react";
import { Autoplay, Thumbs, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import defaultImage from "@/images/blog/16.jpg";
import "swiper/css"
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

const TestimonialsOneCarousel = ({ zoneContent }) => {
  const [sectionData] = zoneContent || [];
  const [testimonialCarouseldata, setTestimonialCarouseldata] = useState();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    if (sectionData) {
      let testimonialContent = JSON.parse(sectionData?.ContentDTO?.ContentObject || "{}");
      if (testimonialContent) {
        let sectionData = {
          title: testimonialContent.title,
          subTitle: testimonialContent.subTitle,
        };

        let imageData = [];
        testimonialContent.images?.map(value => {
          imageData.push({
            name: value.name,
            subTitle: value.subTitle,
            description: value.description,
            image: value.url,
          });
        });
        sectionData.productDetails = imageData.sort(
          (valueOne, valueTwo) => valueOne.sortOrder - valueTwo.sortOrder
        );
        setTestimonialCarouseldata(sectionData);
      }
    }
  }, [sectionData]);

  const testimonialsThumbOptions = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 0,
    speed: 1400,
    pagination: {
      el: "#testimonials-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  };
  const testimonialsOptions = {
    speed: 1400,
    mousewheel: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
  };
  return (
    <>
      {testimonialCarouseldata && (
        <section className="commonSection testimonial_2">
          <Container>
            <Row className="testimoniTab">
              <Col lg={5}>
                <h4 className="sectionSubTitle color_aaa">
                  {testimonialCarouseldata?.subTitle}
                </h4>
                <h2 className="sectionTitle white">
                  {testimonialCarouseldata?.title}
                </h2>
                <Swiper
                  id="testimonial_2_thumb"
                  onSwiper={setThumbsSwiper}
                  slidesPerView={3}
                  slidesPerGroup={3}
                  spaceBetween={0}
                  speed={1400}
                  pagination={{
                    el: "#testimonials-carousel-pagination",
                    type: "bullets",
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 5000,
                  }}
                  modules={[Autoplay, Thumbs, Pagination]}
                >
                  {testimonialCarouseldata?.productDetails.map(
                    ({ name, subTitle, image }, index) => (
                      <SwiperSlide key={index}>
                        <div className="control_item">
                          <a href={`#tab_${index}`} data-toggle="tab">
                            <span>
                              <img
                                src={image ? image : defaultImage}
                                alt={name}
                              />
                            </span>
                            <div className="author_detail">
                              <h5>{name}</h5>
                              <h6>{subTitle}</h6>
                            </div>
                          </a>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </Col>
              <Col lg={7}>
                <div
                  className="swiper-pagination"
                  id="testimonials-carousel-pagination"
                ></div>
                <div className="tab-content">
                  <Swiper
                    thumbs={{ swiper: thumbsSwiper }}
                    speed={1400}
                    mousewheel={true}
                    slidesPerView={1}
                    autoplay={{
                      delay: 5000,
                    }}
                    modules={[Thumbs]}
                  >
                    {testimonialCarouseldata?.productDetails.map(
                      ({ description, date }, index) => (
                        <SwiperSlide key={index}>
                          <div className="testi_con">
                            <p>{description}</p>
                            <span>{date ? date : ""}</span>
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default TestimonialsOneCarousel;
